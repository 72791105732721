import React from 'react';
import ApexCharts from 'react-apexcharts'

type Props = {
    series: any;
}

export default class Pie extends React.Component<Props> {
    state = {
        options: [],
        series: []
    };

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            series: Object.values(nextProps.series),
            options: {
                chart: {
                    height: 350,
                    type: 'pie',
                    zoom: {
                        enabled: true
                    }
                },
                labels: Object.keys(nextProps.series),
                responsive: [{
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      },
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }]
            }
        })
    }

    render() {
        const { options, series } = this.state;

        if (options.length === 0 || series.length === 0)
            return null;

        return (
            <div style={{ width: '100%' }}>
                <ApexCharts options={options} series={series} type="pie" height={350} />
            </div>
        )
    }
}