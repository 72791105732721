import React, { Component } from 'react';
import Joyride, { CallBackProps, STATUS, StoreHelpers } from 'react-joyride';
import Authentication from '../../lib/Authentication';

type State = {
    steps: any,
    run: boolean
};

class OnboardingPayment extends Component<any, State> {

    private helpers?: StoreHelpers;

    state: any = {
        run: Authentication.isFirstAccess(),
        steps: [
            {
                title: 'Pagamento',
                content: 'Gerencie os dados para pagamento e as faturas em aberto.',
                placement: 'right',
                target: '.ant-menu-item-selected'
            },
            {
                title: 'Pagamento',
                content: 'Verifique os dados do seu cartão.',
                target: '.cartao'
            },
            {
                title: 'Pagamento',
                content: 'Gerencie as suas faturas em aberto.',
                target: '.fatura'
            }
        ]
    }

    private getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
    };

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED];


        if (finishedStatuses.includes(status)) {
            this.setState({ run: false });
            Authentication.setIsFirstAccess();
        } else if (status === STATUS.SKIPPED)
            Authentication.setIsFirstAccess();
    };

    componentDidMount = () => {
        this.helpers?.prev()
    }

    render() {

        const { run, steps } = this.state;

        return (
            <Joyride
                callback={this.handleJoyrideCallback}
                continuous={true}
                getHelpers={this.getHelpers}
                run={run}
                scrollToFirstStep={true}
                showProgress={false}
                showSkipButton={true}
                disableCloseOnEsc
                disableScrolling
                steps={steps}
                styles={{
                    options: {
                        primaryColor: '#0c0c0c',
                        zIndex: 10000,
                    },
                    buttonNext: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    buttonBack: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    buttonSkip: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    tooltipContent: {
                        textAlign: 'left'
                    }
                }}
                locale={{
                    next: 'Próximo',
                    back: 'Voltar',
                    skip: 'Continuar depois',
                    last: 'Finalizar',
                }}
            />
        );
    }
}

export default OnboardingPayment;
