import React from 'react'
import { translate as t } from "../../translator/translate";
import { Row, Col, Card, Statistic } from 'antd';
import { UserOutlined, FormOutlined } from '@ant-design/icons';
import moment from 'moment';

export default class StatisticDashboard extends React.Component<any> {
    render() {
        return <Row>
            <Col>
                <Card className="statisticPerson">
                    <Statistic
                        title={t("PESSOAS")}
                        value={this.props.person}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<UserOutlined />}
                    />
                </Card>
            </Col>
            <Col>
                <Card className="statisticIssue">
                    <Statistic
                        title={t("DEMANDAS PENDENTES")}
                        value={this.props.issues.filter(((issue: any) => !issue.isFinished))
                            .filter(((issue: any) => moment().diff(moment(issue.created_at), 'days') < issue.time)).length}
                        valueStyle={{ color: '#f3ac15' }}
                        prefix={<FormOutlined />}
                    />
                </Card>
            </Col>
            <Col>
                <Card className="statisticIssue">
                    <Statistic
                        title={t("DEMANDAS ATRASADAS")}
                        value={this.props.issues
                            .filter(((i: any) => !i.isFinished))
                            .filter(((issue: any) => moment().diff(moment(issue.created_at), 'days') > issue.time)).length}
                        valueStyle={{ color: '#ff5500' }}
                        prefix={<FormOutlined />}
                    />
                </Card>
            </Col>
            <Col>
                <Card className="statisticIssue">
                    <Statistic
                        title={t("DEMANDAS RESOLVIDAS")}
                        value={this.props.issues.filter(((issue: any) => issue.isFinished)).length}
                        valueStyle={{ color: '#269ffb' }}
                        prefix={<FormOutlined />}
                    />
                </Card>
            </Col>
        </Row>
    }
}
