import React from 'react'
import { Layout } from 'antd';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header';

export default class Unauthorized extends React.Component<any> {

    render() {
        return <Layout>
            <Sidebar />
            <Layout className="site-layout">
                <Header />
                <Layout.Content style={{ margin: '10px 10px 0' }}>
                    <div style={{ padding: '20px 0px 20px 20px', minHeight: 360 }}>

                        {/* <Breadcrumb crumbs={[{ name: t("Acesso não autorizado") }]} /> */}

                    </div>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>Gibr ©2020 Created by Dev</Layout.Footer>
            </Layout>
        </Layout>
    }
}
