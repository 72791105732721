import React, { Component } from 'react';
import { DownOutlined } from "@ant-design/icons";
import Authentication from "../lib/Authentication";
import { Layout, Menu, Dropdown } from "antd";
import { Link } from 'react-router-dom';

class Header extends Component {


    doLogout() {
        Authentication.logout();
        document.location.href = "/";
    }

    render() {
        const menu = (
            <Menu>
                {Authentication.getUser().personal?.isMaster
                    ? <Menu.Item>
                        <Link to={'/account'}>
                            Minha conta
                        </Link>
                    </Menu.Item>
                    : null
                }
                <Menu.Item onClick={() => {
                    Authentication.setIsFirstAccess(1);
                    window.location.reload();
                }}>
                    Tutorial onboarding
                </Menu.Item>
                <Menu.Item onClick={this.doLogout}>
                    Logout
                </Menu.Item>
            </Menu>
        );

        return (
            <Layout.Header style={{ background: '#fff' }}>
                <div className="header-info-container">
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: "black" }}>
                            {Authentication.getUserName()} <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
            </Layout.Header >
        );
    }
}

export default Header;
