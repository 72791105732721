import React, { Component } from "react";
import Table, { ColumnProps } from "../../lib/components/Table";
import { Company } from "./interfaces";
import Api from "../../lib/API";
import { Divider, Popconfirm, Button, Input } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, DeleteOutlined, TeamOutlined, UndoOutlined, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';

type State = {
    records: Company[];
    company?: Company;
    showNewRecordModal: boolean;
    loading: boolean;
    searchText: string;
    searchedColumn: string;
};

export default class ListCompany extends Component<any, State> {
    state: State = {
        loading: false,
        records: [],
        showNewRecordModal: false,
        searchText: '',
        searchedColumn: '',
    };

    searchInput: any;


    getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node: any) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                />
                <Divider type="vertical" />
                <Button onClick={() => this.handleReset(clearFilters)}
                    size="small" style={{ width: 90 }}
                    icon={<CloseCircleOutlined />} />

            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text: any) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    columns: ColumnProps<Company>[] = [
        {
            dataIndex: "name",
            title: "Nome",
            ...this.getColumnSearchProps('name'),
        },
        {
            dataIndex: "cnpj",
            title: "CNPJ",

        },
        {
            dataIndex: "email",
            title: "Email",

        },
        {
            dataIndex: "phone",
            title: "Telefone",
        },
        {
            title: "",
            key: "action",
            render: (_, company) => (
                company.deleted_at
                    ? <span>
                        <Popconfirm title={"Deseja reativar esta empresa ou parceiro?"}
                            placement="top"
                            icon={<TeamOutlined />}
                            onConfirm={() => this.onRestore(company)}
                            cancelText={"Cancelar"}
                            okText={"Reativar"}>
                            <UndoOutlined />
                        </Popconfirm>
                    </span>
                    : <span>
                        <EditOutlined onClick={() => { this.props.history.push(`/company/edit/${company.id}`) }} />
                        <Divider type="vertical" />
                        <Popconfirm title={"Deseja remover esta empresa ou parceiro?"}
                            placement="top"
                            icon={<TeamOutlined />}
                            onConfirm={() => this.onDelete(company)}
                            cancelText={"Cancelar"}
                            okText={"Deletar"}
                            okType={"danger"}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </span>
            )
        }
    ];
    componentDidMount(): void {
        this.fetchCompany().catch();
    }

    async fetchCompany() {
        this.setState({ loading: true });
        const records = await Api.get(`/company`);
        this.setState({ records, loading: false });
    }

    onDelete = async (company: Company) => {
        this.setState({ loading: true });

        await Api.delete(`/company/${company.id}`);

        this.fetchCompany();
    };

    onRestore = async (company: Company) => {
        this.setState({ loading: true });

        await Api.put(`/company/${company.id}/restore`);

        this.fetchCompany();
    };

    render() {
        const { loading, records } = this.state;

        return (
            <div>
                {/* <Onboarding history={this.props.history} /> */}
                <div className="flex space-between">
                    <Breadcrumb crumbs={[{ name: "Empresas & Parceiros" }]} />
                    <Button className="right" type="primary" style={{ marginBottom: 16 }}
                        onClick={() => { this.props.history.push(`/company/new`) }}>
                        Nova empresa
                    </Button>
                </div>
                <div style={{ marginTop: 16 }}>
                    <Table
                        loading={loading}
                        columns={this.columns}
                        dataSource={records}
                        style={{ paddingTop: 10 }} />
                </div>
            </div>
        );
    }
}
