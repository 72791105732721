import React, { Component } from "react";
import { Profile, Module } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import Api from "../../../lib/API";
import Breadcrumb from "../../../lib/components/Breadcrumb";
import Authentication from "../../../lib/Authentication";
import TextArea from "antd/lib/input/TextArea";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

type State = {
  profile: Profile | any;
  modules: Array<any>;
  saveLoading: boolean;
};

class CreateProfile extends Component<any, State> {
  state: State = {
    saveLoading: false,
    modules: [],
    profile: {}
  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const modules = await Api.get(`/module`);

    this.setState({ modules });
  }

  saveProfile = async (record: any) => {
    const { modules } = this.state;
    const clientId = Authentication.getUserClient();
    const profile = await Api.post(`/profile`, { name: record.name, description: record.description, clientId });

    modules.forEach(async (m) => {
      await Api.post(`/profileModule`, {
        profileId: profile.id,
        moduleId: m.id,
        actionCreate: record[`${m.id}[actionCreate]`],
        actionUpdate: record[`${m.id}[actionUpdate]`],
        actionDelete: record[`${m.id}[actionDelete]`],
        actionList: record[`${m.id}[actionList]`],
        actionView: record[`${m.id}[actionView]`]
      });
    })

    this.setState({ saveLoading: false })
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveProfile(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/profile');
  };

  get renderModules() {
    const { modules } = this.state;


    return modules.map((module: Module, key: number) =>
      <Col key={key} span={12}>
        <h4 style={{ color: "rgba(0, 0, 0, 0.85)" }}>{module.name}</h4>
        <Row style={{ paddingTop: 0 }}>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionCreate]`} label="Criar">
            <Switch
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionUpdate]`} label="Editar">
            <Switch
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionDelete]`} label="Deletar">
            <Switch
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionList]`} label="Listar" >
            <Switch
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionView]`} label="Visualizar">
            <Switch
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Row>
      </Col>
    )
  }

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    const { saveLoading } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Usuários" }, { name: "Perfis de acesso" }, { name: "Novo perfil" }]} />
        <Row>
          <Col>
            <Card>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical"
                autoComplete="off">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações do perfil</h3></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="name" label="Nome"
                      rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="description" label="Descrição">
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Permissões por módulo</h3></Col>
                </Row>
                <Row>
                  {this.renderModules}
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>Salvar</Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/profile')}>Cancelar</Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
      </div >
    );
  }
}

export default CreateProfile;
