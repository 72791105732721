import React, { Component } from "react";
import Table, { ColumnProps } from "../../lib/components/Table";
import { Issue } from "./interfaces";
import Api from "../../lib/API";
import { Divider, Popconfirm, Button, Radio, Input, Tag, Modal, Form, Row, Col } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, DeleteOutlined, EyeOutlined, TeamOutlined, UndoOutlined, SearchOutlined, CloseCircleOutlined, CheckSquareOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import moment from "moment";
import OnboardingIssue from '../Onboarding/Issue';
import TextArea from "antd/lib/input/TextArea";
import { FormInstance } from "antd/lib/form";
import Authentication from "../../lib/Authentication";


type State = {
  stateFilter: string | boolean;
  records: Issue[];
  issue?: Issue;
  showNewRecordModal: boolean;
  loading: boolean;
  searchText: string;
  searchedColumn: string;
  issueCategory: Array<any>;
  issueSubcategory: Array<any>;
  columns: ColumnProps<Issue>[];
  visible: boolean;
  saveLoading: boolean;
};

export default class ListIssue extends Component<any, State> {
  state: State = {
    loading: false,
    records: [],
    showNewRecordModal: false,
    stateFilter: 'late',
    searchText: '',
    searchedColumn: '',
    issueCategory: [],
    issueSubcategory: [],
    columns: [],
    issue: {} as Issue,
    visible: false,
    saveLoading: false
  };

  private formRef = React.createRef<FormInstance>();
  searchInput: any;

  async fetchFilters() {
    this.setState({ loading: true });
    const issueCategory = await Api.get(`/issueCategory`);
    const issueSubcategory = await Api.get(`/issueSubcategory`);


    const filterIssueCategory = [...issueCategory.map((c: any) => { return { text: c.name, value: c.name } })];
    const filterIssueSubcategory = [...issueSubcategory.map((c: any) => { return { text: c.name, value: c.name } })];
    const columns = [
      {
        dataIndex: "isFinished",
        title: "Status",
        render: (_: any, issue: any) => (
          issue.isFinished
            ? <Tag color="#87d068">FINALIZADA</Tag>
            : moment().diff(moment(issue.created_at), 'days', true) > issue.time
              ? <Tag color="#f50">ATRASADA</Tag>
              : <Tag color="#f3ac15">PENDENTE</Tag>
        )
      },
      {
        dataIndex: "created_at",
        title: "Aberta",
        render: (_: any, issue: any) => moment(issue.created_at).format("DD/MM/YYYY")

      },
      {
        dataIndex: "issueCategory",
        title: "Categoria",
        render: (_: any, issue: any) => issue.issue_category.name,
        filters: filterIssueCategory,
        onFilter: (value: any, record: any) => record.issue_category.name.indexOf(value) === 0
      },
      {
        dataIndex: "issueSubcategory",
        title: "Subcategoria",
        render: (_: any, issue: any) => issue.issue_subcategory.name,
        filters: filterIssueSubcategory,
        onFilter: (value: any, record: any) => record.issue_subcategory.name.indexOf(value) === 0
      },
      {
        dataIndex: "person",
        title: "Solicitante",
        ...this.getColumnSearchProps('person', 'name'),
        render: (_: any, issue: any) => issue.person ? issue.person.name : ''
      },
      {
        dataIndex: "description",
        title: "Solicitação",
        ...this.getColumnSearchProps('description'),
      },
      {
        dataIndex: "userResponsible",
        title: "Responsável",
        ...this.getColumnSearchProps('user_responsible', 'name'),
        render: (_: any, issue: any) => issue.user_responsible ? issue.user_responsible.name : ''
      },
      {
        title: "Ações",
        key: "action",
        width: '10%',
        render: (_: any, issue: any) => (
          issue.deleted_at
            ? <span>
              <Popconfirm title={"Deseja reativar esta demanda?"}
                placement="top"
                icon={<TeamOutlined />}
                onConfirm={() => this.onRestore(issue)}
                cancelText={"Cancelar"}
                okText={"Reativar"}>
                <UndoOutlined />
              </Popconfirm>
            </span>
            : <span>
              <EyeOutlined onClick={() => { this.props.history.push(`/issue/view/${issue.id}`) }} />
              <Divider type="vertical" />
              {!issue.isFinished
                ? <>
                  <CheckSquareOutlined onClick={() => { this.setState({ visible: true, issue: issue }) }} />
                  <Divider type="vertical" />
                  <EditOutlined onClick={() => { this.props.history.push(`/issue/edit/${issue.id}`) }} />
                  <Divider type="vertical" /></>
                : null
              }
              <Popconfirm title={"Deseja remover esta demanda?"}
                placement="top"
                icon={<TeamOutlined />}
                onConfirm={() => this.onDelete(issue)}
                cancelText={"Cancelar"}
                okText={"Deletar"}
                okType={"danger"}>
                <DeleteOutlined />
              </Popconfirm>
            </span>
        )
      }
    ];

    this.setState({ columns });
  }


  getColumnSearchProps = (dataIndex: any, dataIndexTwo: any = null) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
        <Divider type="vertical" />
        <Button onClick={() => this.handleReset(clearFilters)}
          size="small" style={{ width: 90 }}
          icon={<CloseCircleOutlined />} />

      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      dataIndexTwo
        ? record[dataIndex][dataIndexTwo].toString().toLowerCase().includes(value.toLowerCase())
        : record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: any) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async fetchIssue() {
    const { stateFilter } = this.state
    const records = await Api.post(`/issue/filters`, { state: stateFilter });
    this.setState({ records, loading: false });
  }

  onDelete = async (issue: Issue) => {
    this.setState({ loading: true });

    await Api.delete(`/issue/${issue.id}`);

    this.setState({ loading: false }, () => this.fetchIssue());
  };

  onRestore = async (issue: Issue) => {
    this.setState({ loading: true });

    await Api.put(`/issue/${issue.id}/restore`);

    this.setState({ loading: false }, () => this.fetchIssue());
  };

  handleSubmit = async (isFinished: boolean = false) => {
    this.setState({ saveLoading: true })
    const { issue } = this.state;
    const userId = Authentication.getUserId();

    await this.formRef.current?.validateFields().then(async (values) => {
      Api.post(`/issueFollowUp`, { ...values, issueId: issue?.id, userId });
      await Api.put(`/issue/${issue?.id}`, { isFinished, userSolverId: userId, solutionDate: moment().format("YYYY-MM-DD HH:mm:ss") });
      this.formRef.current?.resetFields();
    }).catch(() => this.setState({ saveLoading: false }));

    this.setState({ saveLoading: false, visible: false, loading: true, issue: {} as Issue });
    this.fetchIssue();
  };

  componentDidMount = async () => {
    await this.fetchFilters();
    await this.fetchIssue().catch();
  }

  render() {
    const { loading, records, stateFilter, columns } = this.state;

    return (
      <div>
        <OnboardingIssue history={this.props.history} />
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Demandas" }]} />
          <Button className="right" type="primary" style={{ marginBottom: 16 }}
            onClick={() => { this.props.history.push(`/issue/new`) }}>
            Nova demanda
          </Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Radio.Group
            onChange={({ target: { value } }) => {
              this.setState({ stateFilter: value, loading: true }, () => this.fetchIssue())
            }}
            value={stateFilter}
          >
            <Radio value="withTrashed">Todas</Radio>
            <Radio value="late">Atrasadas</Radio>
            <Radio value={false}>Pendentes</Radio>
            <Radio value={1}>Finalizadas</Radio>
            <Radio value="onlyTrashed">Removidas</Radio>
          </Radio.Group>
          <Table
            loading={loading}
            columns={columns}
            dataSource={records}
            style={{ paddingTop: 10 }} />
        </div>
        <Modal
          title="Follow Up"
          visible={this.state.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form
            style={{ paddingTop: 0 }}
            onFinish={(e) => false}
            ref={this.formRef}
            layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item name="description" label="Descrição" rules={[{ required: true, message: "Preencha o campo" }]}>
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" loading={this.state.saveLoading} onClick={() => this.handleSubmit(true)}>Salvar e finalizar</Button>
                <Button style={{ margin: '0 8px' }} type="default" htmlType="submit" loading={this.state.saveLoading} onClick={() => this.handleSubmit()}>Salvar</Button>
                <Button style={{ margin: '0 8px' }} type="link" onClick={() => { this.setState({ visible: false }) }}>Cancelar</Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
