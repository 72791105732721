import React, { Component } from "react";
import { Issue } from "./interfaces";
import Api from "../../lib/API";
import { Form, Button, Spin, Row, Col, Card, Timeline, Modal, Tag, Popconfirm, Upload, message } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { BellOutlined, DeleteOutlined, EditOutlined, InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import Authentication from "../../lib/Authentication";
import config from "../../config";

type State = {
  issue: Issue | any,
  loading: boolean,
  visible: boolean,
  visibleAlert: boolean,
  saveLoading: boolean,
  issueAlert: Array<any>
};

export default class DetailIssue extends Component<any, State> {
  issueId: string = this.props.match.params.id;
  userId: string = Authentication.getUserId();

  private formRef = React.createRef<FormInstance>();

  private propsUpload = {
    name: 'file',
    multiple: true,
    action: `${config.api}/issue/upload/${this.issueId}`,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} upload realizado com sucesso.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} falha no upload.`);
      }
    },
  };

  state: State = {
    issue: null,
    issueAlert: [],
    loading: false,
    visible: false,
    saveLoading: false,
    visibleAlert: false
  };

  componentDidMount(): void {
    this.fetchIssue().catch();
  }

  async fetchIssue() {
    this.setState({ loading: true });
    const issue = await Api.get(`/issue/${this.issueId}`);
    const issueAlert = await Api.get(`/issueAlert/${this.issueId}`);
    this.setState({ issue, issueAlert, loading: false });
  }

  reopenIssue = async () => {
    this.setState({ loading: true });
    await Api.put(`/issue/${this.issueId}`, { isFinished: false });
    const issue = await Api.post(`/issueFollowUp`, { description: 'Demanda reaberta', issueId: this.issueId, userId: this.userId });
    this.setState({ issue, loading: false });
  }

  deleteIssueAlert = async (issueAlert: any) => {
    await Api.delete(`/issueAlert/${issueAlert.id}`);
    this.fetchIssue();
  }

  handleSubmit = async (isFinished: boolean = false) => {
    this.setState({ saveLoading: true })
    await this.formRef.current?.validateFields().then(async (values) => {
      await Api.post(`/issueFollowUp`, { ...values, issueId: this.issueId, userId: this.userId });
      const issue = await Api.put(`/issue/${this.issueId}`, { isFinished, userSolverId: this.userId, solutionDate: moment().format("YYYY-MM-DD HH:mm:ss") });
      this.formRef.current?.resetFields();
      this.setState({ saveLoading: false, visible: false, issue });
    }).catch(() => this.setState({ saveLoading: false }));
  };


  get renderIssueFollowUp() {
    const { issue } = this.state;

    return issue.issue_follow_up.reverse().map((f: any) =>
      <Timeline.Item>
        <p><b>{f.user.name}</b> em {moment(f.created_at).format("DD/MM/YYYY HH:mm")}</p>
        <p><i>{f.description}</i></p>
      </Timeline.Item>
    )
  }

  get renderIssueAlert() {
    const { issueAlert } = this.state;

    return issueAlert.map((issueAlert: any) =>
      <p>{issueAlert.description} <Popconfirm title={"Deseja remover esta notificação?"}
        placement="top"
        icon={<EditOutlined />}
        onConfirm={() => { this.deleteIssueAlert(issueAlert) }}
        cancelText={"Cancelar"}
        okText={"Deletar"}
        okType={"danger"}>
        <DeleteOutlined />
      </Popconfirm> </p>
    )
  }

  render() {
    const { saveLoading, loading, issue } = this.state;

    if (!issue)
      return <div style={{ textAlign: "center" }}><Spin spinning={loading} /></div>;

    return (
      <div>
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Demandas" }, { name: issue.person.name }]} />
          <Button size="large" className="right" type="link" style={{ marginBottom: 16 }}
            onClick={_ => { this.setState({ visibleAlert: true }) }}>
            Gerenciar notificações <BellOutlined />
          </Button>
        </div>

        <Row>
          <Col md={12}>
            <Row style={{ paddingTop: 0 }}>
              <Col>
                <Card loading={loading}
                  title={
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>Informações da demanda</span>
                      <span>
                        {issue.isFinished
                          ? <Tag color="#87d068">FINALIZADA</Tag>
                          : <Tag color="#f50">PENDENTE</Tag>}
                      </span>
                    </div>}
                  actions={[
                    !issue.isFinished
                      ? <div onClick={() => { this.props.history.push(`/issue/edit/${issue.id}`) }}>
                        <EditOutlined /> Editar demanda
                  </div>
                      : <div onClick={this.reopenIssue}>
                        <EditOutlined /> Reabrir demanda
                </div>
                  ]}>
                  <p><b>Data de abertura:</b> {moment(issue.created_at).format("DD/MM/YYYY HH:mm")}</p>
                  <p><b>Categoria:</b> {issue.issue_category.name}</p>
                  <p><b>Subcategoria:</b> {issue.issue_subcategory.name}</p>
                  <p><b>Órgão responsável:</b> {issue.issue_organ ? issue.issue_organ.name : 'Nenhum'}</p>
                  <p><b>Protocolo externo:</b> {issue.protocol !== '' ? issue.protocol : 'Nenhum'}</p>
                  <p><b>Descrição da solicitação:</b> {issue.description}</p>
                  <p><b>Arquivos:</b></p>
                  <ul>
                    {issue.issue_file
                      ? issue.issue_file.map((file: any, key: any) =>
                        <li key={key}><a href={file.url} target='_blank' rel="noopener noreferrer">{file.filename}</a></li>)
                      : 'Nenhum arquivo'}
                  </ul>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Upload.Dragger {...this.propsUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Clique ou arraste para fazer o upload</p>
                </Upload.Dragger>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Card loading={loading} title="Follow Up" actions={[
              <div onClick={() => { this.setState({ visible: true }) }}>
                <PlusCircleOutlined /> Follow Up
                  </div>
            ]}>
              <Timeline>
                {this.renderIssueFollowUp}
                <Timeline.Item dot={<EditOutlined />} color="green">
                  Criada em {moment(issue.created_at).format("LLL").toString()} por {issue.user_creative.name}
                </Timeline.Item>
              </Timeline>
            </Card>
          </Col>
        </Row>
        <Modal
          title="Follow Up"
          visible={this.state.visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form
            style={{ paddingTop: 0 }}
            onFinish={(e) => e.preventDefault()}
            ref={this.formRef}
            layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item name="description" label="Descrição" rules={[{ required: true, message: "Preencha o campo" }]}>
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit" loading={saveLoading} onClick={() => this.handleSubmit(true)}>Salvar e finalizar</Button>
                <Button style={{ margin: '0 8px' }} type="default" htmlType="submit" loading={saveLoading} onClick={() => this.handleSubmit()}>Salvar</Button>
                <Button style={{ margin: '0 8px' }} type="link" onClick={() => { this.setState({ visible: false }) }}>Cancelar</Button>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Notificações"
          visible={this.state.visibleAlert}
          onOk={() => this.setState({ visibleAlert: false })}
          onCancel={() => this.setState({ visibleAlert: false })}
          footer={null}
        >
          {this.renderIssueAlert}
        </Modal>
      </div>
    );
  }
}