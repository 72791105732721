class Authentication {
    getUser() {
        if (!this.isAuthenticated())
            return {};

        return {
            jwt: localStorage.userJWT,
            personal: {
                id: localStorage.id,
                name: localStorage.userName,
                email: localStorage.userEmail,
                isMaster: (parseInt(localStorage.isMaster) === 1)
            }
        };
    }

    /**
     * Retrieve JWT from local storage
     * @return string JWT
     */
    getToken() {
        return localStorage.userJWT;
    }

    /**
     * Set new JWT token into local storage
     * @return void
     */
    setToken(newToken: string) {
        localStorage.userJWT = newToken;
    }

    /**
     * Set new id into local storage
     * @return void
     */
    setUserId(id: string) {
        localStorage.id = id;
    }

    /**
     * Retrieve id info from local storage
     * @return string
     */
    getUserId() {
        return localStorage.id;
    }

    /**
     * Set new user name into local storage
     * @return void
     */
    setUserName(newUserName: string) {
        localStorage.userName = newUserName;
    }

    /**
     * Retrieve user name info from local storage
     * @return string
     */
    getUserName() {
        return localStorage.userName;
    }

    /**
     * Set new user client into local storage
     * @return void
     */
    setUserClient(newUserClient: string) {
        localStorage.userClient = newUserClient;
    }

    /**
     * Retrieve user client info from local storage
     * @return string
     */
    getUserClient() {
        return localStorage.userClient;
    }

    /**
     * Retrieve user client info from local storage
     * @return string
     */
    isFirstAccess() {
        return parseInt(localStorage.isFirstAccess) === 1;
    }

    /**
     * Retrieve user client info from local storage
     * @return string
     */
    setIsFirstAccess(value: any = 0) {
        localStorage.isFirstAccess = value;
    }

    /**
     * Set new user permissions into local storage
     * @return void
     */
    setUserPermissions(newUserPermissions: [JSON]) {
        localStorage.userPermissions = newUserPermissions;
    }

    /**
     * Retrieve user permissions info from local storage
     * @return string
     */
    getUserPermissions() {
        return JSON.parse(localStorage.userPermissions);
    }

    /**
     * Clean authentication data inside Local Storage
     * @return void
     */
    logout() {
        localStorage.clear()
    }

    /**
     * Returns true in case the current session has not expired yet
     * @return boolean
     */
    isAuthenticated() {
        return (localStorage.userJWT !== "" && localStorage.userJWT !== undefined);
    }

    /**
     * Store session data inside Local Storage
     * @param jwt string
     * @param user object
     * @return void
     */
    login(jwt: string, user: { name: string; email: string; id: string; clientId: string; profile: any, isFirstAccess: boolean }) {
        localStorage.userJWT = jwt;
        localStorage.userName = user.name;
        localStorage.userEmail = user.email;
        localStorage.userClient = user.clientId;
        localStorage.id = user.id
        localStorage.isMaster = user.profile.isDefault;
        localStorage.isFirstAccess = user.isFirstAccess;
        localStorage.userPermissions = JSON.stringify([...user.profile.profile_module.map((pModule: any) => {
            return {
                key: pModule.module.identifier,
                name: pModule.module.name,
                actions: {
                    new: pModule.actionCreate,
                    edit: pModule.actionUpdate,
                    list: pModule.actionList,
                    view: pModule.actionView,
                    delete: pModule.actionDelete
                }
            }
        })]);
    }
}

export default new Authentication();