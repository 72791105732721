const { version } = require("../package.json");

const dev = {
    api: "http://localhost:8080",
    file: {
        upload: "http://localhost:8080/file/upload"
    }
};

const prod = {
    api: "https://api.gibr.com.br",
    file: {
        upload: "https://api.gibr.com.br/file/upload"
    }
};

const config = (process.env.NODE_ENV === "development" ? dev : prod);

export default {
    project_title: "Gibr WEB",
    defaultLanguage: "PT",
    version,

    iugu: {
        script: "https://js.iugu.com/v2",
        accountId: "F94528CCF6574F4AAC38FB1DA8ABC69B",
        debug: process.env.NODE_ENV === "development"
    },

    ...config
}