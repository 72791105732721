import React from 'react';
import ApexCharts from 'react-apexcharts'

type Props = {
    series: any;
    horizontal?: any;
}

export default class Column extends React.Component<Props> {
    state = {
        options: [],
        series: []
    };

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            series: [{
                name: 'Total',
                data: Object.values(nextProps.series)
            }],
            options: {
                chart: {
                    height: 'auto',
                    type: 'bar',
                    toolbar: {
                        tools: {
                            download: false
                        }
                    }
                },
                plotOptions: {
                    bar: {
                      horizontal: nextProps.horizontal ?? false,
                    }
                  },
                xaxis: {
                    categories: Object.keys(nextProps.series),
                }
            }
        })
    }

    render() {
        const { options, series } = this.state;

        if (options.length === 0 || series.length === 0)
            return null;

        return (
            <div style={{ width: '100%' }}>
                <ApexCharts options={options} series={series} type="bar" height="100%" />
            </div>
        )
    }
}