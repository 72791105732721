import React from 'react'
import { Row, Col, Card, message, Button } from 'antd';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import Breadcrumb from '../../lib/components/Breadcrumb';
import API from '../../lib/API';
import { CSVLink } from "react-csv";
import Person from './Person';
import Issue from './Issue';
import OnboardingReports from '../Onboarding/Reports';
import exportFromJSON from 'export-from-json';
import moment from 'moment';

export default class Reports extends React.Component {

    state = {
        data: [],
        loading: false
    }

    getReportByName = async (report: any) => {
        this.setState({ loading: true });
        const data = await API.get(`/report/${report}`);

        if (!data || data.length <= 0)
            message.info("Nenhum dado encontrado para este relatório")
        else 
            this.formatData(data);
    };

    formatData = (people: any) => {
        let data = people.map((person: any) => {
          return {
            'Nome': person.name,
            'Apelido': person.nickname,
            'Data de nascimento': person.birthdate ? moment(person.birthdate).format('DD/MM/YYYY') : '',
            'Email': person.email,
            'Telefone 1': person.phone1,
            'Telefone 2': person.phone2,
            'CEP': person.zipcode,
            'Endereço': person.address,
            'Número': person.number,
            'Complemento': person.complement,
            'Bairro': person.neighborhood,
            'Observações': person.observation,
          };
        })
    
        this.setState({ data, loading: false });
      };

    download = () => {
        const data = this.state.data;
        const fileName = `Relatório de pessoas - ${moment().format('DDMMY')}`;
        const exportType = 'xls';
    
        exportFromJSON({ data, fileName, exportType });
    
        this.setState({ data: [], loading: false });
      };

    treeData = [
        { title: 'Aniversariantes do dia', key: 'getBirthdaysOfTheDay', isLeaf: true },
        { title: 'Aniversariantes do mês', key: 'getBirthdaysOfTheMonth', isLeaf: true }
    ];

    render() {
        return (
            <div>
                <OnboardingReports />
                <Breadcrumb crumbs={[{ name: "Relatórios" }]} />
                <Row>
                    <Col lg={12} md={12} sm={24}>
                        <Card actions={[
                            // <span>
                            //     <FileAddOutlined /> Novo relatório
                            // </span>
                        ]}>
                            <h4>Lista de relatórios padrão</h4>
                            <DirectoryTree
                                multiple
                                defaultExpandAll
                                onSelect={(key) => this.getReportByName(key[0])}
                                treeData={this.treeData}
                            />
                            <hr />

                            <h4 style={{ textAlign: "center" }}>{
                                this.state.data.length <= 0 && !this.state.loading
                                    ? "Selecione um relatório para download"
                                    : <Button type='primary' disabled={this.state.loading} onClick={this.download}>Download</Button>
                            }</h4>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={24}>
                        <Person />
                    </Col>
                    <Col lg={12} md={12} sm={24}>
                        <Issue />
                    </Col>
                </Row>
            </div >
        );
    }
}
