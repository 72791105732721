import React from 'react'
import { Button, Card, Col, Row } from "antd"
import { RouteComponentProps } from "react-router-dom"
import Api from '../../lib/API'

export default class Plan extends React.Component<RouteComponentProps> {
    state = {
        loading: false
    };

    signPlan = async (plan: any) => {
        this.setState({ loading: true });
        await Api.post('/client/my/plan', { plan });
        window.location.href = "/";
    }

    render() {
        return (
            <div className="site-card-wrapper">
                <Row gutter={16}>
                    <Col span={8}>
                        <Card title="PLANO MENSAL" style={{ textAlign: 'center' }} bordered={false}>
                            <h1>R$ 149,97 <small>por mês</small></h1>
                            <h4>pagamento por boleto ou cartão de crédito</h4>
                            <h5><small>*período gratuito de 30 dias</small></h5>
                            <hr />
                            <Button loading={this.state.loading} size="large" type="primary" onClick={() => this.signPlan('plano_unico')}>TESTE GRÁTIS</Button>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="PLANO SEMESTRAL" style={{ textAlign: 'center' }} bordered={false}>
                            <h1>R$ 134,98 <small>por mês</small></h1>
                            <h4>pagamento somente por cartão de crédito</h4>
                            <h5><small>*período gratuito de 30 dias</small></h5>
                            <hr />
                            <Button loading={this.state.loading} size="large" type="primary" onClick={() => this.signPlan('plano_semestral')}>TESTE GRÁTIS</Button>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title="PLANO ANUAL" style={{ textAlign: 'center' }} bordered={false}>
                            <h1>R$ 127,50 <small>por mês</small></h1>
                            <h4>pagamento somente por cartão de crédito</h4>
                            <h5><small>*período gratuito de 30 dias</small></h5>
                            <hr />
                            <Button loading={this.state.loading} size="large" type="primary" onClick={() => this.signPlan('plano_anual')}>TESTE GRÁTIS</Button>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

}
