import React, { Component } from "react";
import { Issue } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select, message, Checkbox } from "antd";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import cep from 'cep-promise';
import Coordinates from "./Form/_Coordinates";
import TextArea from "antd/lib/input/TextArea";
import Authentication from "../../lib/Authentication";
import { PlusCircleOutlined } from "@ant-design/icons";
import NewItem from "./Form/NewItem";

type State = {
  issue: Issue | any;
  saveLoading: boolean;
  loading: boolean;
  people: Array<any>;
  users: Array<any>;
  issueCategoryId: any;
  issueOrgan: Array<any>;
  issueCategories: Array<any>;
  issueSubcategories: Array<any>;
  cities: Array<any>;
  haveSameAddress: boolean;
  newIssueOrgan: boolean;
  newIssueCategory: boolean;
  newIssueSubcategory: boolean;
};

export default class EditIssue extends Component<any, State> {

  issueId: string = this.props.match.params.id;
  userId: string = Authentication.getUserId();

  state: State = {
    saveLoading: false,
    issueCategoryId: null,
    newIssueOrgan: false,
    newIssueCategory: false,
    newIssueSubcategory: false,
    loading: true,
    people: [],
    users: [],
    issueOrgan: [],
    issueCategories: [],
    issueSubcategories: [],
    cities: [],
    issue: {},
    haveSameAddress: true
  };


  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const people = await Api.get(`/person`);
    const users = await Api.get(`/user`);
    const issueOrgan = await Api.get(`/issueOrgan`);
    const issueCategories = await Api.get(`/issueCategory`);
    const cities = await Api.get(`/city`);
    const issueSubcategory = await Api.get(`/issueSubcategory/issue/${this.issueId}`);
    const issueSubcategories = await Api.get(`/issueSubcategory/${issueSubcategory.issueCategoryId}`);

    this.setState({ people, issueOrgan, issueCategories, issueSubcategories, cities, users });
  }

  async fetchIssueSubcategory(issueCategory: any) {
    const issueSubcategories = await Api.get(`/issueSubcategory/${issueCategory}`);

    let { issue } = this.state;
    issue.issueCategoryId = issueCategory;
    issue.issueSubcategoryId = null;
    this.formRef.current?.setFieldsValue(issue);

    this.setState({ issueSubcategories, issue });
  }


  async fetchIssue() {
    let issue: Issue = await Api.get(`/issue/${this.issueId}`);
    this.setState({ loading: false, issue, haveSameAddress: issue.haveSameAddress });
  }

  saveIssue = async (issue: any) => {
    this.setState({ saveLoading: true });
    issue = await this.setCoordinates(issue);
    issue.haveSameAddress = this.state.haveSameAddress;

    const res = await Api.put(`/issue/${this.issueId}`, issue);
    if (res.id)
      await Api.post(`/issueFollowUp`, { description: 'Demanda editada', issueId: res.id, userId: this.userId });

    this.setState({ saveLoading: false })
  };

  setCoordinates = async (issue: any) => {
    const { cities } = this.state;
    const city = cities.find(o => o.id = issue.cityId)
    if (issue.zipcode !== "" && city) {
      const { latitude, longitude } = await Coordinates(`${issue.address}, ${issue.number} - ${issue.neighborhood}, ${city.name} - ${city.uf.code}`);
      issue.latitude = latitude;
      issue.longitude = longitude;
    }

    return issue;
  };

  handleSubmit = async () => {
    this.setState({ saveLoading: true })
    const values = await this.formRef.current?.validateFields();
    await this.saveIssue(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/issue');
  };

  get renderPersonOptions() {
    return this.state.people.map((person, key) => <Select.Option key={key} value={person.id} disabled={person.deleted_at != null}>{person.name}</Select.Option>)
  }

  get renderUserOptions() {
    return this.state.users.map((user, key) => <Select.Option key={key} value={user.id} disabled={user.deleted_at != null}>{user.name}</Select.Option>)
  }

  get renderIssueOrganOptions() {
    return this.state.issueOrgan.map((p, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  get renderIssueCategoryOptions() {
    return this.state.issueCategories.map((p, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  get renderIssueSubcategoryOptions() {
    return this.state.issueSubcategories.map((p, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  get renderCitiesOptions() {
    return this.state.cities.map((c, key) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
  }

  searchZipcode = async (zipcode: any) => {
    let { issue, cities } = this.state;

    try {
      if (zipcode !== "") {
        const res = await cep(zipcode).then().catch();

        issue.zipcode = zipcode;
        issue.address = res.street;
        issue.neighborhood = res.neighborhood;
        issue.cityId = cities.find(o => o.name === res.city).id;
        this.setState({ issue });
        this.formRef.current?.setFieldsValue(issue);
      }
    } catch (ex) {
      message.warning("Digite um CEP válido")
    }
  }

  afterSaveItem = async (res: any) => {
    const { newIssueSubcategory } = this.state;

    if (res) {
      message.success("Salvo com sucesso");
      if (newIssueSubcategory)
        this.setState({ issueSubcategories: res });
      this.fetchTables();
    }

    this.setState({ newIssueCategory: false, newIssueSubcategory: false });

  };

  componentDidMount = async () => {
    await this.fetchTables();
    await this.fetchIssue();
  }

  render() {
    const { saveLoading, loading, haveSameAddress, issueSubcategories, issue, newIssueOrgan, newIssueCategory, newIssueSubcategory, issueCategoryId } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Demandas" }, { name: "Editar demanda" }]} />
        <Row>
          <Col>
            <Card loading={loading}>
              <Form initialValues={issue}
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações básicas</h3></Col>
                </Row>
                <Row>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="personId" label="Solicitante" rules={[{ required: true, message: "Selecione uma categoria" }]}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderPersonOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingRight: 10 }}>
                    <Form.Item name="issueCategoryId"
                      rules={[{ required: true, message: "Selecione uma categoria" }]}
                      label={<span>Categoria <PlusCircleOutlined onClick={() => this.setState({ newIssueCategory: true })} /> </span>}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => this.fetchIssueSubcategory(e.valueOf())}
                      >
                        {this.renderIssueCategoryOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingRight: 10 }}>
                    <Form.Item name="issueSubcategoryId"
                      rules={[{ required: true, message: "Selecione uma subcategoria" }]}
                      label={<span>Subcategoria {issueCategoryId > 0 ? <PlusCircleOutlined onClick={() => this.setState({ newIssueSubcategory: true })} /> : null} </span>}>
                      <Select
                        showSearch
                        disabled={issueSubcategories.length === 0}
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderIssueSubcategoryOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="time" label="Prazo para solução">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} style={{ paddingRight: 10 }}>
                    <Form.Item name="issueOrganId"
                      label={<span>Órgão responśavel <PlusCircleOutlined onClick={() => this.setState({ newIssueOrgan: true })} /> </span>}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderIssueOrganOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingRight: 10 }}>
                    <Form.Item name="protocol" label="Protocolo externo">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="userResponsibleId" label="Responsável" rules={[{ required: true, message: "Selecione o responsável" }]}>
                      <Select>
                        {this.renderUserOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="description" label="Descrição">
                      <TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações de localização</h3></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="haveSameAddress" label="">
                      <Checkbox defaultChecked={haveSameAddress} value={haveSameAddress} onChange={(e) => this.setState({ haveSameAddress: e.target.checked })}>Utilizar endereço do solicitante</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="zipcode" label="CEP">
                      <MaskedInput mask="11111-111"
                        onBlurCapture={(e) => this.searchZipcode(e.target.value)}
                        disabled={haveSameAddress}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="address" label="Endereço">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ paddingRight: 10 }}>
                    <Form.Item name="number" label="Número">
                      <Input type="number" disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="complement" label="Complemento">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="neighborhood" label="Bairro">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="cityId" label="Cidade (UF)">
                      <Select
                        disabled={haveSameAddress}
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderCitiesOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>Salvar</Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/issue')}>Cancelar</Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
        <NewItem
          item="issueOrgan"
          parentId={null}
          title="Novo órgão responsável"
          visible={newIssueOrgan}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newIssueOrgan: false })}
        />
        <NewItem
          item="issueCategory"
          parentId={null}
          title="Nova categoria de demanda"
          visible={newIssueCategory}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newIssueCategory: false })}
        />
        <NewItem
          item="issueSubcategory"
          parentId={issueCategoryId}
          title="Nova subcategoria de demanda"
          visible={newIssueSubcategory}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newIssueSubcategory: false })}
        />
      </div >
    );
  }
}