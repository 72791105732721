import React from 'react'
import Breadcrumb from '../../lib/components/Breadcrumb';
import { Card, Col, Row, List } from 'antd';
import API from '../../lib/API';
import Authentication from '../../lib/Authentication';
import Map from './Map';
import Popover from 'react-floater';
import { Link } from 'react-router-dom';
import OnboardingMap from '../Onboarding/Map';

const Marker = ({ children }: any) => <div className="marker">{children}</div>;

type State = {
    center: any;
    zoom: number;
    client: any;
    people: any;
    peopleByLatLng: any;
    loading: boolean;
};

class MapPerson extends React.Component<any, State> {

    state: State = {
        center: {
            lat: -13.700634,
            lng: -69.7147925
        },
        zoom: 1,
        client: null,
        people: [],
        peopleByLatLng: [],
        loading: true
    }

    fetchClient = async () => {
        const client = await API.get(`/admin/clients/${Authentication.getUserClient()}`);
        this.setState({
            client,
            center: {
                lat: parseFloat(client.city.latitude),
                lng: parseFloat(client.city.longitude)
            },
            zoom: 11,
            loading: false
        });

    }

    fetchPeople = async () => {
        let peopleGroup: any = [];
        const people = await API.get(`/person`);

        people.forEach((person: any) => {
            if (!peopleGroup[`${person.latitude},${person.longitude}`])
                peopleGroup[`${person.latitude},${person.longitude}`] = [];

            peopleGroup[`${person.latitude},${person.longitude}`].push(person)
        });

        this.setState({ people, peopleByLatLng: peopleGroup });
    }

    renderPerson = (people: any) =>
        people.map((person: any, k: any) =>
            <h5>
                <Link target="blank" key={k} to={`/person/view/${person.id}`}>{person.name}</Link>
            </h5>)

    get renderMarkers() {
        let markers = [];
        const { peopleByLatLng } = this.state;

        for (let latLng in peopleByLatLng) {
            const latLngSplit = latLng.split(',');

            const content = <div>
                <div>
                    <h5>Pessoas que residem neste endereço:</h5>
                    {this.renderPerson(peopleByLatLng[latLng])}
                </div>
            </div>

            if (latLng !== ",")
                markers.push(
                    <Marker lat={latLngSplit[0]} lng={latLngSplit[1]}>
                        <Popover ref={latLng} id={latLng} content={content} offset={1} placement="top" disableHoverToClick showCloseButton>
                            <img alt='marker' style={{ cursor: 'pointer' }} src="/assets/img/marker.png" />
                        </Popover>
                    </Marker>
                );
        }

        return markers.map((marker) => marker);
    }

    get renderListPeople() {
        const { people } = this.state;

        return people.map((person: any, key: number) =>
            <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                    const marker: any = this.refs[`${person.latitude},${person.longitude}`];

                    if (this.state.zoom === 15 && marker)
                        marker.handleClick();
                    else if (marker)
                        this.setState({
                            center: {
                                lat: parseFloat(person.latitude),
                                lng: parseFloat(person.longitude)
                            },
                            zoom: 15
                        });
                }
                }>
                <List.Item.Meta
                    key={key}
                    title={person.name}
                    description={person.city
                        ? `${person.address}, ${person.number} ${person.complement} - ${person.neighborhood}, ${person.city.name} - ${person.city.uf.code}`
                        : "Sem endereço cadastrado"}
                />
            </List.Item >
        )
    }

    componentDidMount() {
        this.fetchClient();
        this.fetchPeople();
    }

    render() {
        const { center, zoom, loading } = this.state;

        return (
            <div>
                <OnboardingMap history={this.props.history} />
                <Breadcrumb crumbs={[{ name: "Mapa de pessoas" }]} />
                <Card loading={loading} style={{ marginTop: 16 }} bodyStyle={{ padding: 0 }}>
                    <Row style={{ paddingTop: 0, maxHeight: "58vh", height: "58vh" }}>
                        <Col lg={18} md={18} sm={24}>
                            <Map center={center}
                                zoom={zoom}
                                onChange={({ center, zoom }: any) => this.setState({ center, zoom })}>
                                {this.renderMarkers}
                            </Map>
                        </Col>
                        <Col lg={6} md={6} sm={24} style={{ maxHeight: "58vh", overflowY: "scroll" }}>
                            <List
                                header={<div>Pessoas <p><small>Clique duas vezes para mais informações</small></p></div>}>
                                {this.renderListPeople}
                            </List>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default MapPerson;