import Authentication from './Authentication';
import Request, { JSONHeaders } from './Request';

async function _handleRequest(url: string, methodType: string, dataObject: any = null, headers: any = JSONHeaders) {
    headers = { 
        ...headers,
        "Authorization" : Authentication.getToken()
    }

    return await Request.asyncCall(url, methodType, dataObject, headers);
}

export default class BackendRequest {
    api_url = ""

    constructor(base_url: string) {
        this.api_url = base_url
    }

    async get(url: string, headers: any = JSONHeaders) {
        return await _handleRequest(this.api_url + url, "GET", null, headers);
    }

    async post(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(this.api_url + url, "POST", dataObject, headers);
    }

    async put(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(this.api_url + url, "PUT", dataObject, headers);
    }

    async delete(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(this.api_url + url, "DELETE", dataObject, headers);
    }
}