import React, { Component } from "react";
import { Meeting } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select, DatePicker, message, Checkbox } from "antd";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import cep from 'cep-promise';
import Coordinates from './Form/_Coordinates';
import Authentication from "../../lib/Authentication";
import TextArea from "antd/lib/input/TextArea";

type State = {
  meeting: Meeting | any;
  saveLoading: boolean;
  people: Array<any>;
  cities: Array<any>;
  haveSameAddress: boolean;
};

class CreateMeeting extends Component<any, State> {
  state: State = {
    saveLoading: false,
    people: [],
    cities: [],
    meeting: {},
    haveSameAddress: true
  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const people = await Api.get(`/person`);
    const cities = await Api.get(`/city`);

    this.setState({ people, cities });
  }

  saveMeeting = async (record: any) => {
    const { haveSameAddress } = this.state;
    const clientId = Authentication.getUserClient();
    const userId = Authentication.getUserId();

    record = await this.setCoordinates(record);
    const meeting =  await Api.post(`/meeting`, { ...record, haveSameAddress, clientId, userId });

    this.setState({ saveLoading: false });

    return meeting.id;
  };

  setCoordinates = async (meeting: any) => {
    const { cities } = this.state;
    const city = cities.find(o => o.id = meeting.cityId)
    if (meeting.zipcode !== "" && city) {
      const { latitude, longitude } = await Coordinates(`${meeting.address}, ${meeting.number} - ${meeting.neighborhood}, ${city.name} - ${city.uf.code}`);
      meeting.latitude = latitude;
      meeting.longitude = longitude;
    }

    return meeting;
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    const id = await this.saveMeeting(values);
    this.formRef.current?.resetFields();
    this.props.history.push(`/meeting/view/${id}`);
  };

  get renderPersonOptions() {
    return this.state.people.map((person, key) => <Select.Option key={key} value={person.id} disabled={person.deleted_at != null}>{person.name}</Select.Option>)
  }

  get renderCitiesOptions() {
    return this.state.cities.map((c, key) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
  }

  searchZipcode = async (zipcode: any) => {
    let { meeting, cities } = this.state;

    try {
      if (zipcode !== "") {
        const res = await cep(zipcode).then().catch();

        meeting.zipcode = zipcode;
        meeting.address = res.street;
        meeting.neighborhood = res.neighborhood;
        meeting.cityId = cities.find(o => o.name === res.city).id;
        this.setState({ meeting });
        this.formRef.current?.setFieldsValue(meeting);
      }
    } catch (ex) {
      message.warning("Digite um CEP válido")
    }
  }

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    const { saveLoading, haveSameAddress } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Reunião ou Evento" }, { name: "Nova Reunião ou Evento" }]} />
        <Row>
          <Col>
            <Card>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações básicas</h3></Col>
                </Row>
                <Row>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="host" label="Anfitrião" rules={[{ required: true, message: "Selecione o anfitrião" }]}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderPersonOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="title" label="Nome da Reunião ou Evento" rules={[{ required: true, message: "Declare o nome da Reunião ou Evento" }]}>
                      <Input type="text" />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="when" label="Data do Evento">
                      <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="description" label="Descrição">
                      <TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações de localização</h3></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="haveSameAddress" label="">
                      <Checkbox defaultChecked={haveSameAddress} value={haveSameAddress} onChange={(e) => this.setState({ haveSameAddress: e.target.checked })}>Utilizar endereço do anfitrião</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="zipcode" label="CEP">
                      <MaskedInput mask="11111-111"
                        onBlurCapture={(e) => this.searchZipcode(e.target.value)}
                        disabled={haveSameAddress}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="address" label="Endereço">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ paddingRight: 10 }}>
                    <Form.Item name="number" label="Número">
                      <Input type="number" disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="complement" label="Complemento">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="neighborhood" label="Bairro">
                      <Input disabled={haveSameAddress} />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="cityId" label="Cidade (UF)">
                      <Select
                        disabled={haveSameAddress}
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderCitiesOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>
                      Salvar
                    </Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/meeting')}>
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
      </div >
    );
  }
}

export default CreateMeeting;
