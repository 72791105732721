import React, { Component } from "react";
import { Person } from "./interfaces";
import Api from "../../lib/API";
import { Spin, Row, Col, Descriptions, Card, Timeline, Divider, Table, Tag } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EyeOutlined, EditOutlined, UserAddOutlined, SyncOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/lib/table"
import moment from "moment";
import { Issue } from "../Issue/interfaces";
import { Link } from "react-router-dom";

type State = {
  person: Person | any,
  links: Array<Person> | any,
  loading: boolean,
  currentPage: any
};

export default class DetailPerson extends Component<any, State> {
  personId: string = this.props.match.params.id;

  state: State = {
    person: null,
    links: [],
    loading: false,
    currentPage: 1
  };


  columns: ColumnProps<Person>[] = [
    {
      dataIndex: "name",
      title: "Nome"
    },
    {
      title: "",
      key: "action",
      render: (_, person) => (
        <span>
          <EyeOutlined onClick={() => { this.props.history.push(`/person/view/${person.id}`) }} />
          <Divider type="vertical" />
          <EditOutlined onClick={() => { this.props.history.push(`/person/edit/${person.id}`) }} />
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  columnsIssues: ColumnProps<Issue>[] = [
    {
      dataIndex: "isFinished",
      title: "Status",
      render: (_: any, issue: any) => (
        issue.isFinished
          ? <Tag color="#87d068">FINALIZADA</Tag>
          : <Tag color="#f50">PENDENTE</Tag>
      )
    },
    {
      dataIndex: "created_at",
      title: "Aberta",
      render: (_: any, issue: any) => moment(issue.created_at).format("DD/MM/YYYY")

    },
    {
      dataIndex: "issueSubcategory",
      title: "Subcategoria",
      render: (_: any, issue: any) => issue.issue_subcategory.name,
    },
    {
      dataIndex: "userResponsible",
      title: "Responsável",
      render: (_: any, issue: any) => issue.user_responsible.name
    },
    {
      title: "Ações",
      key: "action",
      render: (_: any, issue: any) => (
        <span>
          <EyeOutlined onClick={() => { this.props.history.push(`/issue/view/${issue.id}`) }} />
        </span>
      )
    }
  ];

  componentDidMount(): void {
    this.fetchPerson().catch();
    Api.post(`/person/filters`, { personId: this.personId, currentPage: this.state.currentPage }).then((links) => this.setState({ links: links.data }));
  }

  componentWillReceiveProps(nextProps: any) {
    this.personId = nextProps.match.params.id;
    this.fetchPerson().catch();
    Api.post(`/person/filters`, { personId: this.personId, currentPage: this.state.currentPage }).then(links => this.setState({ links: links.data }));
  }

  async fetchPerson() {
    this.setState({ loading: true });
    const person = await Api.get(`/person/${this.personId}`);
    this.setState({ person, loading: false });
  }

  render() {
    const { loading, person, links } = this.state;

    if (!person)
      return <div style={{ textAlign: "center" }}><Spin spinning={loading} /></div>;

    return (
      <div>
        <div className="flex" style={{ alignItems: 'baseline' }}>
          <Breadcrumb crumbs={[{ name: "Pessoas" }, { name: loading ? 'Carregando...' : person.name }]} />
          <Divider type="vertical" />
          <Link to={`/issue/new?person_id=${this.personId}`}>Criar uma nova demanda</Link>
        </div>
        <Row>
          <Col md={12}>
            <Row style={{ paddingTop: 0 }}>
              <Col>
                <Card loading={loading} title="Informações pessoais" actions={[
                  <div onClick={() => { this.props.history.push(`/person/edit/${person.id}`) }}>
                    <EditOutlined /> Editar eleitor
                  </div>
                ]}>
                  <Descriptions>
                    <Descriptions.Item label="Data de nascimento">{person?.birthdate}</Descriptions.Item>
                    <Descriptions.Item label="Telefone 1">{person?.phone1}</Descriptions.Item>
                    <Descriptions.Item label="Telefone 2">{person?.phone2}</Descriptions.Item>
                    <Descriptions.Item label="Email">{person?.email}</Descriptions.Item>
                    <Descriptions.Item label="Observações">{person?.observation}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
              <Col style={{ paddingTop: 16 }}>
                <Card title="Vínculos" loading={loading}>
                  <Table showHeader={false} size="small" columns={this.columns} dataSource={links} onChange={(pagination) => this.setState({ currentPage: pagination.current })}/>
                </Card>
              </Col>
              <Col style={{ paddingTop: 16 }}>
                <Card title="Demandas" loading={loading}>
                  <Table showHeader={false} size="small" columns={this.columnsIssues} dataSource={person.issues} />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Card loading={loading} title="Timeline">
              <Timeline>
                <Timeline.Item dot={<SyncOutlined />} color="blue">
                  Última atualização em {moment(person.update_at).format("LLL").toString()}
                </Timeline.Item>
                <Timeline.Item dot={<UserAddOutlined />} color="green">
                  Criando em {moment(person.created_at).format("LLL").toString()}
                </Timeline.Item>
              </Timeline>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}