import React, { Component } from "react";
// import Table, { ColumnProps } from "../../lib/components/Table";

import { Table } from "antd"
import { ColumnProps } from "antd/lib/table"



import { Person } from "./interfaces";
import Api from "../../lib/API";
import { Divider, Popconfirm, Button, Radio, Input } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, DeleteOutlined, EyeOutlined, TeamOutlined, UndoOutlined, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import Onboarding from "../Onboarding/Person";


type State = {
  stateFilter: string;
  records: any;
  person?: Person;
  showNewRecordModal: boolean;
  loading: boolean;
  searchText: string;
  searchedColumn: string;
  currentPage: any;
};

export default class ListPerson extends Component<any, State> {
  state: State = {
    loading: false,
    records: [],
    showNewRecordModal: false,
    stateFilter: "withoutTrashed",
    searchText: '',
    searchedColumn: '',
    currentPage: 1
  };

  searchInput: any;


  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
        <Divider type="vertical" />
        <Button onClick={() => this.handleReset(clearFilters)}
          size="small" style={{ width: 90 }}
          icon={<CloseCircleOutlined />} />

      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({ currentPage: 1 });
    this.fetchPerson(selectedKeys[0])
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '', currentPage: 1 });
  };


  columns: ColumnProps<Person>[] = [
    {
      dataIndex: "name",
      title: "Nome",
      ...this.getColumnSearchProps('name'),
    },
    {
      dataIndex: "email",
      title: "Email",

    },
    {
      dataIndex: "phone1",
      title: "Telefone",
    },
    {
      dataIndex: "link",
      title: "Vínculo",
      render: (_: any, person: any) => person.link ? person.link.name : "Nenhum vínculo",
    },
    {
      title: "",
      key: "action",
      render: (_, person) => (
        person.deleted_at
          ? <span>
            <Popconfirm title={"Deseja reativar este eleitor?"}
              placement="top"
              icon={<TeamOutlined />}
              onConfirm={() => this.onRestore(person)}
              cancelText={"Cancelar"}
              okText={"Reativar"}>
              <UndoOutlined />
            </Popconfirm>
          </span>
          : <span>
            <EyeOutlined onClick={() => { this.props.history.push(`/person/view/${person.id}`) }} />
            <Divider type="vertical" />
            <EditOutlined onClick={() => { this.props.history.push(`/person/edit/${person.id}`) }} />
            <Divider type="vertical" />
            <Popconfirm title={"Deseja remover este eleitor?"}
              placement="top"
              icon={<TeamOutlined />}
              onConfirm={() => this.onDelete(person)}
              cancelText={"Cancelar"}
              okText={"Deletar"}
              okType={"danger"}>
              <DeleteOutlined />
            </Popconfirm>
          </span>
      )
    }
  ];
  componentDidMount(): void {
    this.fetchPerson().catch();
  }

  async fetchPerson(name = '') {
    this.setState({ loading: true });
    const { stateFilter, currentPage } = this.state
    const records: any = await Api.post(`/person/filters`, { state: stateFilter, currentPage, name });
    this.setState({ records, loading: false });
  }

  onDelete = async (person: Person) => {
    this.setState({ loading: true });

    await Api.delete(`/person/${person.id}`);

    this.fetchPerson();
  };

  onRestore = async (person: Person) => {
    this.setState({ loading: true });

    await Api.put(`/person/${person.id}/restore`);

    this.setState({ stateFilter: "withoutTrashed" }, () => this.fetchPerson());
  };

  render() {
    const { loading, records, stateFilter } = this.state;

    return (
      <div>
        <Onboarding history={this.props.history} />
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Pessoas" }]} />
          <Button className="right" type="primary" style={{ marginBottom: 16 }}
            onClick={() => { this.props.history.push(`/person/new`) }}>
            Novo eleitor
          </Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Radio.Group
            onChange={({ target: { value } }) => {
              this.setState({ stateFilter: value }, () => this.fetchPerson())
            }}
            value={stateFilter}
          >
            <Radio value="withTrashed">Todos</Radio>
            <Radio value="withoutTrashed">Somente ativos</Radio>
            <Radio value="onlyTrashed">Somente inativos</Radio>
          </Radio.Group>
          <Table
            loading={loading}
            columns={this.columns}
            dataSource={records.data}
            style={{ paddingTop: 10 }}
            pagination={{
              total: records.total
            }}
            onChange={(pagination, filters:any) => this.setState({ currentPage: pagination.current }, () => this.fetchPerson(filters.name ? filters.name[0] : ''))}
          />
        </div>
      </div>
    );
  }
}
