import React from 'react'
import GoogleMapReact from 'google-map-react';

class Map extends React.Component<any> {

    state = {
        ...this.props
    }
    
    componentWillReceiveProps = (props: any) => this.setState({ ...props });

    render() {
        const { center, zoom }: any = this.state;

        return (

            <GoogleMapReact
                options={{
                    fullscreenControl: false,
                    streetViewControl: true
                }}
                onChange={this.state.onChange}
                bootstrapURLKeys={{ key: 'AIzaSyC6uXCi-wns5s9Gw1W38abgU67e_Eyuk84' }}
                center={center}
                zoom={zoom}
            >
                {this.props.children}
            </GoogleMapReact>
        );
    }
}

export default Map;