import React, { Component } from "react";
import Api from "../../lib/API";
import { Calendar } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import Authentication from "../../lib/Authentication";
import queryString from 'query-string';
import moment from "moment";
import OnboardingCalendar from "../Onboarding/Calendar";


type State = {
    loading: boolean;
    calendar?: any;
};

export default class ListCalendar extends Component<any, State> {
    state: State = {
        loading: false,
        calendar: []
    };


    componentDidMount(): void {
        let code = null;
        if (this.props.location.search) {
            const qs = queryString.parse(this.props.location.search);
            code = qs.code;
        }

        if (!code)
            this.fetchCalendar().catch();
        else
            this.createCalendar(code)
    }

    async fetchCalendar() {
        this.setState({ loading: true });
        const calendar = await Api.get(`/clientCalendar`);

        this.setState({ calendar, loading: false });
    }

    sendUserToGoogleOAuth = () => window.location.href = this.state.calendar;

    async createCalendar(googleCalendarToken: any) {
        console.log(await Api.post('/clientCalendar', { googleCalendarToken, clientId: Authentication.getUserClient() }));
        window.location.href = '/calendar';
    }

    get listEvents() {
        return this.state.calendar.map((e: any, key: any) =>
            <p key={key}>{e.summary}</p>)
    }

    dateCellRender = (value: any) => {
        const events = this.state.calendar.filter((e: any) => (moment(e.start.dateTime || e.start.date).format('L') === value.format('L')));
        return (
            events.map((item: any) => (
                <a href={item.htmlLink} target="_blank" rel="noopener noreferrer">{item.summary}</a>
            ))
        );
    }

    monthCellRender = (value: any) => {
        const events = this.state.calendar.filter((e: any) => (moment(e.start.dateTime || e.start.date).format('MYYYY') === value.format('MYYYY')));
        return (
            events.map((item: any) => (
                <a href={item.htmlLink} target="_blank" rel="noopener noreferrer">{item.summary}</a>
            ))
        );
    }

    render() {
        const { loading, calendar } = this.state;

        return (
            <div>
                <OnboardingCalendar history={this.props.history} />
                <div className="flex space-between">
                    <Breadcrumb crumbs={[{ name: "Agenda" }]} />
                </div>
                {Array.isArray(calendar) ? <p className="googleCalendar">No momento o acesso a agenda é apenas de visualização, para administrar seus eventos acesse o <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer">Google Agenda</a>.</p> : ''}
                <div style={{ marginTop: 16 }}>
                    {Array.isArray(calendar)
                        ? loading
                            ? "Carregando eventos..."
                            : <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} />
                        : <h4>
                            Para começar a utilizar esta funcionalidade você precisa vincular sua conta GiBR com a sua conta no Google Agenda. Você terá somente uma agenda, dessa forma toda a equipe estará alinhada.
                            <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={this.sendUserToGoogleOAuth}>
                                <img alt="calendar" src="/assets/img/calendar.png" width="50px" />
                                <p>
                                    Clique aqui para configurar
                                </p>
                            </div>
                        </h4>
                    }
                </div>
            </div>
        );
    }
}
