import React, { Component } from "react";
import { Person } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select, DatePicker, message, AutoComplete } from "antd";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import cep from 'cep-promise';
import Coordinates from "../_Coordinates";
import Authentication from "../../lib/Authentication";
import NewItem from "./Form/NewItem";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

type State = {
  person: Person | any;
  saveLoading: boolean;
  people: Array<any>;
  personCategory: Array<any>;
  religion: Array<any>;
  occupation: Array<any>;
  cities: Array<any>;
  locals: Array<any>;
  newPersonCategory: boolean;
  newOccupation: boolean;
  newReligion: boolean;
};

class CreatePerson extends Component<any, State> {
  state: State = {
    saveLoading: false,
    newPersonCategory: false,
    newOccupation: false,
    newReligion: false,
    people: [],
    personCategory: [],
    religion: [],
    occupation: [],
    cities: [],
    person: {},
    locals: []
  };

  private formRef = React.createRef<FormInstance>();

  fetchPeople = async () => this.setState({ people: await Api.get(`/person`) });
  fetchPersonCategory = async () => this.setState({ personCategory: await Api.get(`/personCategory`) });
  fetchReligion = async () => this.setState({ religion: await Api.get(`/religion`) });
  fetchOccupation = async () => this.setState({ occupation: await Api.get(`/occupation`) });
  fetchCities = async () => this.setState({ cities: await Api.get(`/city`) });
  fetchLocals = async () => this.setState({ locals: await Api.get(`/localToVote`) });


  async fetchTables() {
    this.fetchPeople();
    this.fetchPersonCategory();
    this.fetchReligion();
    this.fetchOccupation();
    this.fetchCities();
    this.fetchLocals();
  }


  savePerson = async (record: any) => {
    const clientId = Authentication.getUserClient();

    record = await this.setCoordinates(record);
    await Api.post(`/person`, { ...record, clientId });

    this.setState({ saveLoading: false })
  };

  setCoordinates = async (person: any) => {
    const { cities } = this.state;
    const city = cities.find(o => o.id === person.cityId)

    if (city) {
      const { latitude, longitude } = await Coordinates(`${person.address}, ${person.number} - ${person.neighborhood}, ${city.name} - ${city.uf.code}`);
      person.latitude = latitude;
      person.longitude = longitude;
    }

    return person;
  };

  handleSubmit = async () => {
    this.setState({ saveLoading: true });
    const values = await this.formRef.current?.validateFields();
    await this.savePerson(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/person');
  };

  get renderPersonOptions() {
    return this.state.people.map((person, key) =>
      person.deleted_at == null
        ? <Select.Option key={key} value={person.id}>{person.name}</Select.Option>
        : null)
  }

  get renderPersonAutoCompleteOptions() {
    return this.state.people.map((person, key) =>
      <AutoComplete.Option key={key} value={person.id}>{person.name}</AutoComplete.Option>)
  }

  get renderPersonCategoryOptions() {
    return this.state.personCategory.map((p, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  get renderReligionOptions() {
    return this.state.religion.map((r, key) => <Select.Option key={key} value={r.id}>{r.name}</Select.Option>)
  }

  get renderOccupationOptions() {
    return this.state.occupation.map((o, key) => <Select.Option key={key} value={o.id}>{o.name}</Select.Option>)
  }

  get renderCitiesOptions() {
    return this.state.cities.map((c, key) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
  }

  get renderLocalsOptions() {
    return this.state.locals.map((l, key) => <Select.Option key={key} value={l.id}>{l.name} ({l.zone})</Select.Option>)
  }

  searchZipcode = async (zipcode: any) => {
    let { person, cities } = this.state;

    try {
      if (zipcode !== "") {
        const res = await cep(zipcode).then().catch();

        person.zipcode = zipcode;
        person.address = res.street;
        person.neighborhood = res.neighborhood;
        person.cityId = cities.find(o => o.name === res.city).id;
        this.setState({ person });
        this.formRef.current?.setFieldsValue(person);
      }
    } catch (ex) {
      message.warning("Digite um CEP válido");
    }
  }

  afterSaveItem = async (res: any) => {
    if (res) {
      message.success("Salvo com sucesso");
      this.fetchTables();
    }

    this.setState({ newPersonCategory: false, newReligion: false, newOccupation: false });

  };

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    const { saveLoading, newPersonCategory, newOccupation, newReligion } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Pessoas" }, { name: "Nova pessoa" }]} />
        <Row>
          <Col>
            <Card>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical"
                autoComplete="off">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações pessoais</h3></Col>
                </Row>
                <Row>
                  <Col span={12} style={{ paddingRight: 10 }}>
                    <Form.Item name="name" label="Nome"
                      rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <AutoComplete
                        onSelect={(personId) => this.props.history.push(`/person/edit/${personId}`)}
                        filterOption={(inputValue, option) =>
                          option?.children.toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                        }
                      >
                        {this.renderPersonAutoCompleteOptions}
                      </AutoComplete>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="nickname" label="Apelido">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="sex" label="Sexo">
                      <Select>
                        <Select.Option value={1}>Feminino</Select.Option>
                        <Select.Option value={2}>Masculino</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="birthdate" label="Data de nascimento">
                      <DatePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="email" label="Email">
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="phone1" label="Telefone">
                      <MaskedInput mask="(11) 11111-1111" />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="phone2" label="Telefone (opcional)">
                      <MaskedInput mask="(11) 11111-1111" />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="personId" label="Vínculo">
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderPersonOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="personCategoryId"
                      label={<span>Categoria <PlusCircleOutlined onClick={() => this.setState({ newPersonCategory: true })} /> </span>}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderPersonCategoryOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="localToVoteId" label="Local de votação">
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderLocalsOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="publicity" label="Propaganda">
                      <Select>
                        <Select.Option value={1}>Nenhuma</Select.Option>
                        <Select.Option value={2}>Adesivo veicular</Select.Option>
                        <Select.Option value={3}>Placa</Select.Option>
                        <Select.Option value={4}>Perfurite</Select.Option>
                        <Select.Option value={5}>Todos</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="occupationId"
                      label={<span>Profissão <PlusCircleOutlined onClick={() => this.setState({ newOccupation: true })} /> </span>}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderOccupationOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="religionId"
                      label={<span>Religião <PlusCircleOutlined onClick={() => this.setState({ newReligion: true })} /> </span>}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderReligionOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="observation" label="Observações">
                      <TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações de endereço</h3></Col>
                </Row>
                <Row>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="zipcode" label="CEP">
                      <MaskedInput mask="11111-111"
                        onBlurCapture={(e) => this.searchZipcode(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ paddingRight: 10 }}>
                    <Form.Item name="address" label="Endereço">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ paddingRight: 10 }}>
                    <Form.Item name="number" label="Número">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="complement" label="Complemento">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="neighborhood" label="Bairro">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="cityId" label="Cidade (UF)">
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderCitiesOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>
                      Salvar
                    </Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/person')}>
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
        <NewItem
          item="personCategory"
          title="Nova categoria de pessoa"
          visible={newPersonCategory}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newPersonCategory: false })}
        />
        <NewItem
          item="occupation"
          title="Nova profissão"
          visible={newOccupation}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newOccupation: false })}
        />
        <NewItem
          item="religion"
          title="Nova religião"
          visible={newReligion}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newReligion: false })}
        />
      </div >
    );
  }
}

export default CreatePerson;
