import React, { Component } from "react";
import Table, { ColumnProps } from "../../lib/components/Table";
import { Local } from "./interfaces";
import Api from "../../lib/API";
import { Divider, Button, Input, Popconfirm } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, EyeOutlined, SearchOutlined, CloseCircleOutlined, TeamOutlined, DeleteOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import OnboardingLocal from "../Onboarding/Local";


type State = {
  stateFilter: string;
  records: Local[];
  local?: Local;
  showNewRecordModal: boolean;
  loading: boolean;
  searchText: string;
  searchedColumn: string;
};

export default class ListLocal extends Component<any, State> {
  state: State = {
    loading: false,
    records: [],
    showNewRecordModal: false,
    stateFilter: "withoutTrashed",
    searchText: '',
    searchedColumn: '',
  };

  searchInput: any;


  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
        <Divider type="vertical" />
        <Button onClick={() => this.handleReset(clearFilters)}
          size="small" style={{ width: 90 }}
          icon={<CloseCircleOutlined />} />

      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: any) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  onDelete = async (localToVote: Local) => {
    this.setState({ loading: true });

    await Api.delete(`/localToVote/${localToVote.id}`);

    this.fetchLocal();
  };

  columns: ColumnProps<Local>[] = [
    {
      dataIndex: "name",
      title: "Nome",
      ...this.getColumnSearchProps('name'),
    },
    {
      dataIndex: "zone",
        title: "Zona",
        ...this.getColumnSearchProps('zone'),
    },
    {
      title: "",
      key: "action",
      render: (_, local) => (
        <span>
          <EyeOutlined onClick={() => { this.props.history.push(`/local/view/${local.id}`) }} />
          <Divider type="vertical" />
          <EditOutlined onClick={() => { this.props.history.push(`/local/edit/${local.id}`) }} />
          <Divider type="vertical" />
          <Popconfirm title={"Deseja remover este local?"}
            placement="top"
            icon={<TeamOutlined />}
            onConfirm={() => this.onDelete(local)}
            cancelText={"Cancelar"}
            okText={"Deletar"}
            okType={"danger"}>
            <DeleteOutlined />
          </Popconfirm>
        </span>
      )
    }
  ];
  componentDidMount(): void {
    this.fetchLocal().catch();
  }

  async fetchLocal() {
    this.setState({ loading: true });
    const records = await Api.get(`/localToVote`);
    this.setState({ records, loading: false });
  }

  render() {
    const { loading, records } = this.state;

    return (
      <div>
        <OnboardingLocal history={this.props.history} />
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Locais de votação" }]} />
          <Button className="right" type="primary" style={{ marginBottom: 16 }}
            onClick={() => { this.props.history.push(`/local/new`) }}>
            Novo local de votação
          </Button>
        </div>
        <div style={{ marginTop: 16 }}>
          <Table
            loading={loading}
            columns={this.columns}
            dataSource={records}
            style={{ paddingTop: 10 }} />
        </div>
      </div >
    );
  }
}
