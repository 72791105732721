import React from 'react';
import Authentication from '../lib/Authentication';

// @ts-ignore
import { Redirect } from "react-router-dom"
import { NonAuthRoutes } from "./Routes";
import { message } from 'antd';

const ProtectedRoutes = (props: any): JSX.Element => {
    const isLoggedIn = Authentication.isAuthenticated();
    const req = props.location.pathname.split("/").filter(Boolean);

    if (!isLoggedIn)
        return <Redirect to={NonAuthRoutes.login} />;

    if (req.length <= 0)
        return <Redirect to="/dashboard" />

    const userPermissions = Authentication.getUserPermissions();
    const permission = userPermissions.find((permission: any) =>
        req[0] === permission.key)

    if (!permission
        || (!req[1] && !permission.actions.list)
        || (req[1] && !permission.actions[req[1]])) {
        message.error('Acesso não autorizado');
        if (req[1])
            return <Redirect to={`/${req[0]}`} />
        else
            return <Redirect to="/unauthorized" />
    }

    return props.children;
};

export default ProtectedRoutes;