import React, { Component } from "react";
import { Company } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select, message } from "antd";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import cep from 'cep-promise';
import Coordinates from "../_Coordinates";
import TextArea from "antd/lib/input/TextArea";

type State = {
    company: Company | any;
    loading: boolean;
    saveLoading: boolean;
    cities: Array<any>;
};

class CreateCompany extends Component<any, State> {

    companyId: string = this.props.match.params.id;

    state: State = {
        company: {},
        saveLoading: false,
        loading: true,
        cities: [],
    };

    private formRef = React.createRef<FormInstance>();

    async fetchCompany() {
        let company = await Api.get(`/company/${this.companyId}`);
        this.setState({ loading: false, company });
    }

    async fetchTables() {
        const cities = await Api.get(`/city`);
        this.setState({ cities });
    }

    saveCompany = async (record: any) => {
        record = await this.setCoordinates(record);
        await Api.put(`/company/${this.companyId}`, record);

        this.setState({ saveLoading: false })
    };

    setCoordinates = async (company: any) => {
        const { cities } = this.state;
        const city = cities.find(o => o.id === company.cityId)

        if (city) {
            const { latitude, longitude } = await Coordinates(`${company.address}, ${company.number} - ${company.neighborhood}, ${city.name} - ${city.uf.code}`);
            company.latitude = latitude;
            company.longitude = longitude;
        }

        return company;
    };

    handleSubmit = async () => {
        this.setState({ saveLoading: true });
        const values = await this.formRef.current?.validateFields();
        await this.saveCompany(values);
        this.formRef.current?.resetFields();
        this.props.history.push('/company');
    };

    get renderCitiesOptions() {
        return this.state.cities.map((c, key) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
    }


    searchZipcode = async (zipcode: any) => {
        let { company, cities } = this.state;

        try {
            if (zipcode !== "") {
                const res = await cep(zipcode).then().catch();

                company.zipcode = zipcode;
                company.address = res.street;
                company.neighborhood = res.neighborhood;
                company.cityId = cities.find(o => o.name === res.city).id;
                this.setState({ company });
                this.formRef.current?.setFieldsValue(company);
            }
        } catch (ex) {
            message.warning("Digite um CEP válido");
        }
    }

    componentDidMount = async () => {
        await this.fetchTables();
        await this.fetchCompany();
    }

    render() {
        const { company, saveLoading, loading } = this.state;

        return (
            <div>
                <Breadcrumb crumbs={[{ name: "Empresas & Parceiros" }, { name: "Editar empresa" }]} />
                <Row>
                    <Col>
                        <Card loading={loading}>
                            <Form
                                onFinish={this.handleSubmit}
                                initialValues={company}
                                ref={this.formRef}
                                layout="vertical"
                                autoComplete="off">
                                <Row style={{ paddingTop: 0 }}>
                                    <Col><h3>Informações pessoais</h3></Col>
                                </Row>
                                <Row>
                                    <Col span={12} style={{ paddingRight: 10 }}>
                                        <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ paddingRight: 10 }}>
                                        <Form.Item name="cnpj" label="CNPJ">
                                            <MaskedInput mask="11.111.111/1111-11" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8} style={{ paddingRight: 10 }}>
                                        <Form.Item name="email" label="Email">
                                            <Input type="email" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{ paddingRight: 10 }}>
                                        <Form.Item name="phone" label="Telefone">
                                            <MaskedInput mask="(11) 11111-1111" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ paddingRight: 10 }}>
                                        <Form.Item name="observation" label="Observações">
                                            <TextArea rows={5} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 0 }}>
                                    <Col><h3>Informações de endereço</h3></Col>
                                </Row>
                                <Row>
                                    <Col span={3} style={{ paddingRight: 10 }}>
                                        <Form.Item name="zipcode" label="CEP">
                                            <MaskedInput mask="11111-111"
                                                onBlurCapture={(e) => this.searchZipcode(e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} style={{ paddingRight: 10 }}>
                                        <Form.Item name="address" label="Endereço">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} style={{ paddingRight: 10 }}>
                                        <Form.Item name="number" label="Número">
                                            <Input type="number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} style={{ paddingRight: 10 }}>
                                        <Form.Item name="complement" label="Complemento">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} style={{ paddingRight: 10 }}>
                                        <Form.Item name="neighborhood" label="Bairro">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5} style={{ paddingRight: 10 }}>
                                        <Form.Item name="cityId" label="Cidade (UF)">
                                            <Select
                                                showSearch
                                                filterOption={(input, option: any) =>
                                                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {this.renderCitiesOptions}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Button type="primary" htmlType="submit" loading={saveLoading}>
                                            Salvar
                                        </Button>
                                        <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/company')}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row >
            </div >
        );
    }
}

export default CreateCompany;
