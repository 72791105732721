import React, { Component } from "react";
import { Local } from "./interfaces";
import Api from "../../lib/API";
import { Spin, Row, Col, Descriptions, Card, Table, Divider } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Person } from "../Person/interfaces";
import { ColumnProps } from "../../lib/components/Table";

type State = {
  local: Local | any;
  people: Array<Person> | Array<any>;
  loading: boolean;
};

export default class DetailLocal extends Component<any, State> {
  localId: string = this.props.match.params.id;

  state: State = {
    local: null,
    people: [],
    loading: false,
  };

  columns: ColumnProps<Person>[] = [
    {
      dataIndex: "name",
      title: "Nome"
    },
    {
      title: "",
      key: "action",
      render: (_, person) => (
        <span>
          <EyeOutlined onClick={() => { this.props.history.push(`/person/view/${person.id}`) }} />
          <Divider type="vertical" />
          <EditOutlined onClick={() => { this.props.history.push(`/person/edit/${person.id}`) }} />
          <Divider type="vertical" />
        </span>
      )
    }
  ];

  componentDidMount(): void {
    this.fetchLocal().catch();
  }

  async fetchLocal() {
    this.setState({ loading: true });
    const local = await Api.get(`/localToVote/${this.localId}`);
    const people = await Api.post(`/person/filters`, { localToVoteId: this.localId });
    this.setState({ local, people, loading: false });
  }

  render() {
    const { loading, local, people } = this.state;

    if (!local)
      return <div style={{ textAlign: "center" }}><Spin spinning={loading} /></div>;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Locais de votação" }, { name: local.name }]} />
        <Row>
          <Col md={12}>
            <Row style={{ paddingTop: 0 }}>
              <Col>
                <Card loading={loading} title="Informações pessoais" actions={[
                  <div onClick={() => { this.props.history.push(`/local/edit/${local.id}`) }}>
                    <EditOutlined /> Editar local de votação
                  </div>
                ]}>
                  <Descriptions>
                    <Descriptions.Item label="Zona">{local?.zone}</Descriptions.Item>
                    <Descriptions.Item label="Votantes no local">{local?.voters}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Card loading={loading} title="Pessoas">
              <Table size="small" columns={this.columns} dataSource={people} />
            </Card>
          </Col>
        </Row>
      </div >
    );
  }
}