import React from 'react'
import { Row, Col, Card, message, Select, Form, Button, DatePicker } from 'antd';
import { FormInstance } from "antd/lib/form";
import Api from '../../lib/API';
import exportFromJSON from 'export-from-json';
import Authentication from '../../lib/Authentication';
import moment from 'moment';

export default class Issue extends React.Component {
    private formRef = React.createRef<FormInstance>();

    state = {
        data: [],
        loading: false,
        headers: [],
        people: [],
        issueCategory: [],
        issueSubcategory: []
    }

    async fetchTables() {
        const people = await Api.get(`/person`);
        const issueCategory = await Api.get(`/issueCategory`);

        this.setState({ people, issueCategory });
    }

    get renderPersonOptions() {
        return this.state.people.map((person: any, key) =>
            person.deleted_at == null
                ? <Select.Option key={key} value={person.id}>{person.name}</Select.Option>
                : null)
    }

    get renderIssueCategoryOptions() {
        return this.state.issueCategory.map((p: any, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
    }

    get renderIssueSubcategoryOptions() {
        return this.state.issueSubcategory.map((p: any, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
    }

    async fetchIssueSubcategory(issueCategory: any) {
        const issueSubcategory = await Api.get(`/issueSubcategory/${issueCategory}`);

        this.setState({ issueSubcategory })
    }

    handleSubmit = async () => {
        const values = await this.formRef.current?.validateFields();
        const clientId = Authentication.getUserClient();

        this.setState({ loading: true });

        let data = await Api.post(`/issue/filters`, { ...values, clientId });

        if (!data || data.length <= 0)
            message.info("Nenhum dado encontrado para este relatório")

        this.formatData(data);
    };

    formatData = (issues: any) => {
        let data = issues.map((issue: any) => {
            return {
                'ID': issue.id,
                'Criado em': moment(issue.created_at).format('DD/MM/YYYY'),
                'Status': issue.isFinished ? 'Finalizada' : 'Pendente',
                'Solicitante': issue.person.name,
                'Telefone': issue.person.phone1,
                'Categoria': issue.issue_category.name,
                'Subcategoria': issue.issue_subcategory.name,
                'Órgão': issue.issue_organ ? issue.issue_organ.name : '',
                'Protocolo': issue.protocol,
                'Solicitação': issue.description,
                'Solucionado em': issue.isFinished ? moment(issue.solutionDate).format('DD/MM/YYYY') : ''
            };
        })

        this.setState({ data, loading: false });
    };

    download = () => {
        const data = this.state.data;
        const fileName = `Relatório de demandas - ${moment().format('DDMMY')}`;
        const exportType = 'xls';

        exportFromJSON({ data, fileName, exportType });

        this.setState({ data: [], loading: false });
    };

    componentDidMount = async () => {
        await this.fetchTables();
    }

    render() {
        return (

            <Card>
                <h3>Demandas</h3>
                <Form ref={this.formRef} layout="vertical" autoComplete="off">
                    <Row style={{ paddingTop: 0 }}>
                        <Col>
                            <Form.Item name="start" label="Criados após">
                                <DatePicker format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="end" label="Criados até">
                                <DatePicker format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingRight: 10 }}>
                            <Form.Item name="personId" label="Solicitante">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {this.renderPersonOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item label="Categoria" name="issueCategoryId">
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => this.fetchIssueSubcategory(e.valueOf())}
                                >
                                    {this.renderIssueCategoryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item label="Subcategoria" name="issueSubcategoryId">
                                <Select
                                    showSearch
                                    disabled={this.state.issueSubcategory.length === 0}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.renderIssueSubcategoryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <hr />

                <h4 style={{ textAlign: "center" }}>{
                    this.state.data.length <= 0
                        ? <Button type="default" onClick={this.handleSubmit} loading={this.state.loading}>Gerar relatório</Button>
                        : <Button type='primary' onClick={this.download}>Download</Button>
                }</h4>
            </Card>
        );
    }
}
