import React, { Component } from "react";
import { Local } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select, message } from "antd";
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import cep from 'cep-promise';
import Coordinates from "./Form/_Coordinates";
import Authentication from "../../lib/Authentication";

type State = {
  local: Local | any;
  saveLoading: boolean;
  cities: Array<any>;
};

class CreateLocal extends Component<any, State> {
  state: State = {
    saveLoading: false,
    local: {},
    cities: []
  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const cities = await Api.get(`/city`);
    this.setState({ cities });
  }


  saveLocal = async (record: any) => {
    const clientId = Authentication.getUserClient();

    record = await this.setCoordinates(record);
    await Api.post(`/localToVote`, { ...record, clientId });

    this.setState({ saveLoading: false })
  };

  setCoordinates = async (local: any) => {
    const { cities } = this.state;
    const city = cities.find(o => o.id === local.cityId)
    if (city) {
      const { latitude, longitude } = await Coordinates(`${local.address}, ${local.number} - ${local.neighborhood}, ${city.name} - ${city.uf.code}`);
      local.latitude = latitude;
      local.longitude = longitude;
    }

    return local;
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveLocal(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/local');
  };

  get renderCitiesOptions() {
    return this.state.cities.map((c, key) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
  }

  searchZipcode = async (zipcode: any) => {
    let { local, cities } = this.state;

    try {
      if (zipcode !== "") {
        const res = await cep(zipcode).then().catch();

        local.zipcode = zipcode;
        local.address = res.street;
        local.neighborhood = res.neighborhood;
        local.cityId = cities.find(o => o.name === res.city).id;
        this.setState({ local });
        this.formRef.current?.setFieldsValue(local);
      }
    } catch (ex) {
      message.warning("Digite um CEP válido");
    }
  }

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    const { saveLoading } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Locais de votação" }, { name: "Novo local de votação" }]} />
        <Row>
          <Col>
            <Card>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical"
                autoComplete="off">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações básica</h3></Col>
                </Row>
                <Row>
                  <Col span={16} style={{ paddingRight: 10 }}>
                    <Form.Item name="name" label="Nome" rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="zone" label="Zona">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ paddingRight: 10 }}>
                    <Form.Item name="voters" label="Votantes no local">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações de endereço</h3></Col>
                </Row>
                <Row>
                  <Col span={3} style={{ paddingRight: 10 }}>
                    <Form.Item name="zipcode" label="CEP">
                      <MaskedInput mask="11111-111"
                        onBlurCapture={(e) => this.searchZipcode(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9} style={{ paddingRight: 10 }}>
                    <Form.Item name="address" label="Endereço">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ paddingRight: 10 }}>
                    <Form.Item name="number" label="Número">
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="neighborhood" label="Bairro">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingRight: 10 }}>
                    <Form.Item name="cityId" label="Cidade (UF)">
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderCitiesOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>
                      Salvar
                                        </Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/local')}>
                      Cancelar
                                        </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
      </div >
    );
  }
}

export default CreateLocal;
