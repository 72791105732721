import React from 'react'
import { Button, Form, Input, message } from "antd"
import Api from "../../lib/API";
import { RouteComponentProps } from "react-router-dom"
import passValid from 'password-validator'
import { translate as t } from "../../translator/translate";
import Authentication from "../../lib/Authentication";

const pass = new passValid()
pass.is().min(8).has().uppercase().has().lowercase().has().digits().has().not().spaces()

export default class PasswordReset extends React.Component<RouteComponentProps> {
    state = {
        loading: false
    };

    render() {
        return (
            <div className="screen-container">
                <div className="image-background">
                    <img src="/assets/img/planalto.png" alt='back' style={{ width: "100%", height: 600 }} />
                </div>
                <div className="form-container">
                    <Form name="login" layout="vertical" onFinish={this.handleSubmit}>
                        <h1 style={{ textAlign: "center" }}>
                            <img src="/assets/img/logo.png" alt='logo' width="125" />
                        </h1>
                        <h4 style={{ textAlign: "center" }}>Bem-vindo ao sistema</h4>

                        <Form.Item name="password" label="Senha" rules={[{ required: true, message: "Digite sua senha" }]}>
                            <Input type="password" name="password" />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirme sua senha"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme sua senha',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {

                                        console.log(value)

                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('As duas senhas que você digitou não são iguais');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <h5>8 caracteres</h5>
                        <h5>1 letra de A-Z maiúscula</h5>
                        <h5>1 letra de a-z minúscula</h5>
                        <h5>1 número de 0-9</h5>
                        <hr />

                        <div className="flex align-center">
                            {/* <Link to="/login/" className="fs-20">{t("Esqueceu sua senha?")}</Link> */}
                            <Button loading={this.state.loading} type="primary" htmlType="submit">{t("ALTERAR SENHA")}</Button>
                        </div>
                        {/* <div className="flex align-center space-between">
                            <Link to="/signup/" className="fs-20">{t("Criar minha conta")}</Link>
                        </div> */}
                    </Form>
                </div>
            </div>
        );
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });

        if (!pass.validate(values.password)) {
            this.setState({ loading: false });
            return message.warning('Sua senha não é segura')
        }

        const userId = Authentication.getUserId();
        await Api.put(`/user/password/${userId}`, { password: values.password, isFirstAccess: false, resetPassword: false });

        this.setState({ loading: false });
        this.props.history.push("/dashboard")

    }
}
