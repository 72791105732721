import React from 'react';
import ApexCharts from 'react-apexcharts'
import moment from 'moment';

type Props = {
    series: any;
}

export default class ChartDash extends React.Component<Props> {
    state = {
        options: [],
        series: []
    };

    componentDidMount() {
        this.setState({
            series: this.props.series,
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Pessoas por mês/ano',
                    align: 'left'
                },
                yaxis: {
                    labels: { formatter: (val: any) => val.toFixed() }
                },
                xaxis: {
                    categories: moment.monthsShort()
                },
                tooltip: {
                    y: {
                        title: {
                            formatter: (val: any) => `Registros em ${val}: `
                        }
                    }

                },
                grid: {
                    borderColor: '#f1f1f1',
                }
            }
        })
    }

    render() {
        const { options, series } = this.state;

        if (options.length === 0 && series.length === 0)
            return null;

        return (
            <div style={{ width: '100%' }}>
                <ApexCharts options={options} series={series} type="line" height={400} />
            </div>
        )
    }
}