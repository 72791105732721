import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import 'moment/locale/pt-br';
import 'simplebar/dist/simplebar.min.css';
import 'dayjs/locale/pt-br'
import dayjs from "dayjs";
dayjs.locale('pt-br');

ReactDOM.render(<App />, document.getElementById('root'));
