import React, { Component } from "react";
import { Profile, Module } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import Api from "../../../lib/API";
import Breadcrumb from "../../../lib/components/Breadcrumb";
import TextArea from "antd/lib/input/TextArea";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

type State = {
  loading: boolean;
  saveLoading: boolean;
  profile: Profile | any;
  modules: Array<any>;
}

export default class EditProfile extends Component<any, State> {

  profileId: string = this.props.match.params.id;

  state: State = {
    profile: {},
    saveLoading: false,
    loading: true,
    modules: [],

  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const modules = await Api.get(`/module`);

    this.setState({ modules });
  }

  async fetchProfile() {
    let profile: any = await Api.get(`/profile/${this.profileId}`);

    this.setState({ loading: false, profile });
  }

  saveProfile = async (profile: any) => {
    const { modules } = this.state;
    this.setState({ saveLoading: true });

    await Api.put(`/profile/${this.profileId}`, profile);

    modules.forEach(async (m) => {
      await Api.put(`/profileModule/${this.profileId}/${m.id}`, {
        actionCreate: profile[`${m.id}[actionCreate]`],
        actionUpdate: profile[`${m.id}[actionUpdate]`],
        actionDelete: profile[`${m.id}[actionDelete]`],
        actionList: profile[`${m.id}[actionList]`],
        actionView: profile[`${m.id}[actionView]`]
      });
    })

    this.setState({ saveLoading: false })
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveProfile(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/profile');
  };

  moduleActionIsChecked = (moduleId: any, action: any) => {
    const moduleAction = this.state.profile.profile_module.find((o: any) => o.moduleId === moduleId);
    if (moduleAction)
      return moduleAction[action];
    return false;
  }

  get renderModules() {
    const { modules } = this.state;


    return modules.map((module: Module, key: number) =>
      <Col key={key} span={12}>
        <h4 style={{ color: "rgba(0, 0, 0, 0.85)" }}>{module.name}</h4>
        <Row style={{ paddingTop: 0 }}>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionCreate]`} label="Criar">
            <Switch
              defaultChecked={this.moduleActionIsChecked(module.id, 'actionCreate')}
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionUpdate]`} label="Editar">
            <Switch
              defaultChecked={this.moduleActionIsChecked(module.id, 'actionUpdate')}
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionDelete]`} label="Deletar">
            <Switch
              defaultChecked={this.moduleActionIsChecked(module.id, 'actionDelete')}
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionList]`} label="Listar" >
            <Switch
              defaultChecked={this.moduleActionIsChecked(module.id, 'actionList')}
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item style={{ paddingRight: 25 }} name={`${module.id}[actionView]`} label="Visualizar">
            <Switch
              defaultChecked={this.moduleActionIsChecked(module.id, 'actionView')}
              style={{ marginTop: -30 }}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </Row>
      </Col>
    )
  }

  componentDidMount = async () => {
    await this.fetchProfile();
    await this.fetchTables();
  }

  render() {
    const { loading, saveLoading, profile } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Usuários" }, { name: "Perfis de acesso" }, { name: "Editar perfil" }]} />
        <Row>
          <Col>
            <Card loading={loading}>
              <Form initialValues={profile}
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações do perfil</h3></Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="name" label="Nome"
                      rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ paddingRight: 10 }}>
                    <Form.Item name="description" label="Descrição">
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Permissões por módulo</h3></Col>
                </Row>
                <Row>
                  {this.renderModules}
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>
                      Salvar
                                        </Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/profile')}>
                      Cancelar
                                        </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
      </div >
    );
  }
}