import React from 'react'
import { translate as t } from "../../translator/translate";
import Breadcrumb from '../../lib/components/Breadcrumb';
import StatisticDashboard from './StatisticDash'
import { Row, Col, Card, Modal } from 'antd';
import ChartDash from './ChartDash';
import Api from '../../lib/API';
import Onboarding from '../Onboarding/Dashboard';

type State = {
  loading: boolean,
  person: Array<any>,
  issue: Array<any>,
  personPerYearAndMonth: Array<any>,
  personPerYearAndMonthToChart: any
};


export default class Dashboard extends React.Component<any, State> {

  state: any = {
    loading: true,
    person: [],
    issue: [],
    personPerYearAndMonth: [],
    personPerYearAndMonthToChart: []
    }

  getPersonPerYearAndMonth = async () => this.setState({ personPerYearAndMonth: await Api.get(`/dashboard`) }, () => this.mountChart());
  getCountPerson = async () => this.setState({ person: await Api.get(`/dashboard/person`) });
  getCountIssue = async () => this.setState({ issue: await Api.post(`/issue/filters`) });

  mountChart = () => {
    const { personPerYearAndMonth } = this.state;
    let personPerYearAndMonthToChart: any = [];

    personPerYearAndMonth.forEach((e: any) => {
      if (!personPerYearAndMonthToChart.find((o: any) => o.name === e.year))
        personPerYearAndMonthToChart.push({
          name: e.year,
          data: Array(12).fill(0)
        })

      let p = personPerYearAndMonthToChart.find((o: any) => o.name === e.year)
      p.data[e.month - 1] = e.total;
    });

    this.setState({ personPerYearAndMonthToChart, loading: false });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.getCountIssue();
    this.getCountPerson();
    this.getPersonPerYearAndMonth();
  }

  render() {
    const { loading, person, issue, personPerYearAndMonthToChart } = this.state;
    return <div className='dashboard'>
      <Onboarding history={this.props.history} />

      <Breadcrumb crumbs={[{ name: t("Dashboard") }]} />

      <StatisticDashboard loading={loading} person={person} issues={issue} />

      <Row>
        <Col>
          <Card className="chartOne" size="small" loading={loading}>
            <ChartDash series={[...personPerYearAndMonthToChart]} />
          </Card>
        </Col>
      </Row>
    </div>;
  }
}
