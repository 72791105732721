import React, { Component } from "react";
import { User } from "./interfaces";
import { Form, Input, Card, Row, Col, Button, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import Api from "../../lib/API";
import Breadcrumb from "../../lib/components/Breadcrumb";
import Authentication from "../../lib/Authentication";

type State = {
  user: User | any;
  saveLoading: boolean;
  profile: Array<any>;
};

class CreateUser extends Component<any, State> {
  state: State = {
    saveLoading: false,
    profile: [],
    user: {},
  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const profile = await Api.get(`/profile`);

    this.setState({ profile });
  }


  saveUser = async (record: any) => {
    const clientId = Authentication.getUserClient();

    await Api.post(`/user`, { ...record, clientId });

    this.setState({ saveLoading: false })
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveUser(values);
    this.formRef.current?.resetFields();
    this.props.history.push('/user');
  };

  get renderProfileOptions() {
    return this.state.profile.map((p, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    const { saveLoading } = this.state;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Usuários" }, { name: "Novo usuário" }]} />
        <Row>
          <Col>
            <Card>
              <Form
                onFinish={this.handleSubmit}
                ref={this.formRef}
                layout="vertical"
                autoComplete="off">
                <Row style={{ paddingTop: 0 }}>
                  <Col><h3>Informações para acesso</h3></Col>
                </Row>
                <Row>
                  <Col span={9} style={{ paddingRight: 10 }}>
                    <Form.Item name="name" label="Nome"
                      rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={9} style={{ paddingRight: 10 }}>
                    <Form.Item name="email" label="Email"
                      rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingRight: 10 }}>
                    <Form.Item name="profileId" label="Perfil"
                      rules={[{ required: true, message: "Selecione um perfil para salvar" }]}>
                      <Select
                        showSearch
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {this.renderProfileOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={saveLoading}>
                      Salvar
                                        </Button>
                    <Button style={{ margin: '0 8px' }} onClick={() => this.props.history.push('/user')}>
                      Cancelar
                                        </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row >
      </div >
    );
  }
}

export default CreateUser;
