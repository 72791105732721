import React from 'react'
import { translate as t } from "../../translator/translate";
import Breadcrumb from '../../lib/components/Breadcrumb';
import { Row, Col, Card, Form, Input, message, Table, Tag, Button } from 'antd';
import Api from '../../lib/API';
import moment from 'moment';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import Config from '../../config';
import Cards from 'react-credit-cards';

import 'react-credit-cards/es/styles-compiled.css';

//@ts-ignore
import Script from "react-load-script";
import OnboardingPayment from '../Onboarding/Payment';
import { EyeOutlined } from '@ant-design/icons';

type State = {
  loading: boolean,
  saveLoading: boolean,
  client: any,
  invoices: Array<any>,
  paymentMethod: any
};

export default class Payment extends React.Component<any, State> {

  state: any = {
    saveLoading: false,
    loading: true,
    client: null,
    invoices: [],
    paymentMethod: null
  }

  private formRef = React.createRef<FormInstance>();

  private columns = [
    {
      dataIndex: "status",
      title: "Status",
      render: (_: any, invoice: any) => (
        invoice.status === "paid"
          ? <Tag color="#87d068">PAGA</Tag>
          : invoice.status === "pending"
            ? <Tag color="#f50">PENDENTE</Tag>
            : <Tag color="#00aace">CANCELADA</Tag>
      )
    },
    {
      dataIndex: "due_date",
      title: "Vencimento",
      render: (_: any, invoice: any) => moment(invoice.due_date).format("DD/MM/YYYY")

    },
    {
      dataIndex: "total",
      title: "R$",
      render: (_: any, invoice: any) => invoice.total,
    },
    {
      title: "",
      key: "action",
      render: (_: any, invoice: any) => (
        <span>
          <EyeOutlined onClick={() => window.open(invoice.secure_url, "_blank")} />
        </span>
      )
    }
  ]

  async fetchTables() {
    const client = await Api.get(`/client/my`);
    const invoices = await Api.get(`/client/my/invoices`);
    const paymentMethod = await Api.get(`/client/my/paymentMethod`);

    this.setState({ client, paymentMethod, invoices, loading: false });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this.fetchTables();
  }

  handleSubmit = async () => {
    this.setState({ saveLoading: true });
    const values = await this.formRef.current?.validateFields();

    const paymentToken = await this.getIuguPaymentToken(values);

    if (paymentToken !== false) {
      const ret = await Api.post(`/client/my/paymentMethod`, { token: paymentToken });
      if (ret.id)
        message.success(t("O cartão de crédito foi salvo com sucesso"));
      else
        message.error(t("Algo deu errado"));
    }
    else
      message.error(t("O cartão de crédito informado não é válido. Por gentileza, tente novamente."));
  };

  render() {
    const { loading, invoices, paymentMethod } = this.state;
    return <div>
      <OnboardingPayment />
      <Breadcrumb crumbs={[{ name: t("Pagamento") }]} />
      <Row>
        <Col>
          <Row style={{ paddingTop: 0 }}>
            <Col span={12}>
              <Card className="cartao" loading={loading} title="Dados de pagamento">
                {paymentMethod === null || paymentMethod.status === 500
                  ? <Form name="creditCard" layout="vertical"
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                  >
                    <Script url={Config.iugu.script} />

                    <Row style={{ paddingTop: 0 }}>
                      <Col>
                        <Form.Item name="cc_name" label={t("Nome no cartão:")} rules={[{ required: true, message: t("O nome no cartão de crédito é obrigatório.") }]}>
                          <Input type="text" placeholder={t("Ex: João da Silva")} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item name="cc_number" label={t("Número do cartão:")} rules={[{ required: true, message: t("O número do cartão de crédito é obrigatório.") }]}>
                          <MaskedInput mask="1111 1111 1111 1111" placeholder={t("Ex: 0000 0000 0000 0000")} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 0 }}>
                      <Col span={8}>
                        <Form.Item name="cc_month" label={t("Mês do vencimento:")} rules={[{ required: true, message: t("O mês de vencimento do cartão de crédito é obrigatório.") }]}>
                          <MaskedInput mask="11" placeholder={t("Ex: 06")} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="cc_year" label={t("Ano do vencimento:")} rules={[{ required: true, message: t("O ano de vencimento do cartão de crédito é obrigatório.") }]}>
                          <MaskedInput mask="1111" placeholder={t("Ex: 2022")} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name="cc_secret" label={t("Código de verificação:")} rules={[{ required: true, message: t("O código secreto do cartão de crédito é obrigatório.") }]}>
                          <MaskedInput mask="111" placeholder={t("Ex: 123")} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <hr />

                    <div className="flex align-center space-between">
                      <Button htmlType="submit" loading={loading} type="primary">{t("Salvar")}</Button>
                    </div>
                  </Form>
                  : <div>
                    <Cards
                      cvc={""}
                      expiry={paymentMethod.data.month + '/' + paymentMethod.data.year}
                      focused={"name"}
                      name={paymentMethod.data.holder_name}
                      number={paymentMethod.data.first_digits + '******' + paymentMethod.data.last_digits}
                    />
                  </div>
                }
              </Card>
            </Col>
            <Col span={12}>
              <Card className="fatura" loading={loading} title="Histórico de mensalidades">
                <Table
                  loading={loading}
                  columns={this.columns}
                  dataSource={invoices}
                  style={{ paddingTop: 10 }} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>;
  }

  getIuguPaymentToken = (values: any) => {
    const { cc_name, cc_number, cc_month, cc_year, cc_secret } = values;

    const explodedName = cc_name.split(" ");
    const firstName = explodedName.shift();
    const lastName = explodedName.length ? explodedName.join(" ") : "";

    //@ts-ignore
    const cc = Iugu.CreditCard(cc_number, cc_month, cc_year, firstName, lastName, cc_secret);

    return new Promise(res => {
      //@ts-ignore
      Iugu.setAccountID(Config.iugu.accountId);

      //@ts-ignore
      Iugu.setTestMode(Config.iugu.debug);

      //@ts-ignore
      Iugu.createPaymentToken(cc, response => {
        if (response.errors)
          res(false);
        else
          res(response.id);
      });
    });
  }
}
