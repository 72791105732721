import React from 'react'
import { Row, Col, Card, message, Select, Form, Button } from 'antd';
import { FormInstance } from "antd/lib/form";
import Api from '../../lib/API';
import exportFromJSON from 'export-from-json';
import Authentication from '../../lib/Authentication';
import moment from 'moment';

export default class Person extends React.Component {
  private formRef = React.createRef<FormInstance>();

  state = {
    data: [],
    loading: false,
    people: [],
    personCategory: [],
    religion: [],
    occupation: [],
    cities: [],
    locals: [],
    neighborhood: []
  }


  async fetchTables() {
    const people = await Api.get(`/person`);
    const neighborhood = await Api.get(`/person/neighborhood`);
    const personCategory = await Api.get(`/personCategory`);
    const religion = await Api.get(`/religion`);
    const occupation = await Api.get(`/occupation`);
    const cities = await Api.get(`/city`);
    const locals = await Api.get(`/localToVote`);

    this.setState({ people, neighborhood, personCategory, religion, occupation, cities, locals });
  }

  get renderPersonOptions() {
    return this.state.people.map((person: any, key) =>
      person.deleted_at == null
        ? <Select.Option key={key} value={person.id}>{person.name}</Select.Option>
        : null)
  }

  get renderPersonCategoryOptions() {
    return this.state.personCategory.map((p: any, key) => <Select.Option key={key} value={p.id}>{p.name}</Select.Option>)
  }

  get renderReligionOptions() {
    return this.state.religion.map((r: any, key) => <Select.Option key={key} value={r.id}>{r.name}</Select.Option>)
  }

  get renderOccupationOptions() {
    return this.state.occupation.map((o: any, key) => <Select.Option key={key} value={o.id}>{o.name}</Select.Option>)
  }

  get renderNeighborhoodOptions() {
    return this.state.neighborhood.map((n: any, key) => <Select.Option key={key} value={n.neighborhood}>{n.neighborhood}</Select.Option>)
  }

  get renderLocalsOptions() {
    return this.state.locals.map((l: any, key) => <Select.Option key={key} value={l.id}>{l.name}</Select.Option>)
  }

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    const clientId = Authentication.getUserClient();

    this.setState({ loading: true });

    let data = await Api.post(`/person/report/filters`, { ...values, clientId });

    if (!data || data.length <= 0)
      message.info("Nenhum dado encontrado para este relatório");

    this.formatData(data);
  };

  formatPublicity(publicity: any) {
    switch (publicity) {
      case 1:
        publicity = "Nenhuma";
        break;
      case 2:
        publicity = "Adesivo veicular";
        break;
      case 3:
        publicity = "Placa";
        break;
      case 4:
        publicity = "Perfurite";
        break;
      case 5:
        publicity = "Todos";
        break;
      default:
        publicity = "Não informado";
        break;
    }

    return publicity;
  }

  formatData = (people: any) => {
    let data = people.map((person: any) => {
      return {
        'Nome': person.name,
        'Apelido': person.nickname,
        'Data de nascimento': person.birthdate ? moment(person.birthdate).format('DD/MM/YYYY') : '',
        'Email': person.email,
        'Telefone 1': person.phone1,
        'Telefone 2': person.phone2,
        'CEP': person.zipcode,
        'Endereço': person.address,
        'Número': person.number,
        'Complemento': person.complement,
        'Bairro': person.neighborhood,
        'Propaganda': this.formatPublicity(person.publicity),
        'Cidade': person.city ? person.city.name : '',
        'Estado': person.city ? person.city.uf.name : '',
        'Vínculo': person.link ? person.link.name : '',
        'Categoria': person.person_category ? person.person_category.name : '',
        'Profissão': person.occupation ? person.occupation.name : '',
        'Religião': person.religion ? person.religion.name : '',
        'Local de Votação': person.local_to_vote ? person.local_to_vote.nam : '',
        'Observações': person.observation,
      };
    })

    this.setState({ data, loading: false });
  };

  download = () => {
    const data = this.state.data;
    const fileName = `Relatório de pessoas - ${moment().format('DDMMY')}`;
    const exportType = 'xls';

    exportFromJSON({ data, fileName, exportType });

    this.setState({ data: [], loading: false });
  };

  componentDidMount = async () => {
    await this.fetchTables();
  }

  render() {
    return (

      <Card>
        <h3>Pessoas</h3>
        <Form
          ref={this.formRef}
          layout="vertical"
          autoComplete="off">
          <Row style={{ paddingTop: 0 }}>
            <Col><h4>Informações pessoais</h4></Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col span={8} style={{ paddingRight: 10 }}>
              <Form.Item name="sex" label="Sexo">
                <Select allowClear>
                  <Select.Option value={1}>Feminino</Select.Option>
                  <Select.Option value={2}>Masculino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ paddingRight: 10 }}>
              <Form.Item name="personId" label="Vínculo">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderPersonOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col style={{ paddingRight: 10 }}>
              <Form.Item name="localToVoteId" label="Local de votação">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderLocalsOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col span={12} style={{ paddingRight: 10 }}>
              <Form.Item name="publicity" label="Propaganda">
                <Select>
                  <Select.Option value={1}>Nenhuma</Select.Option>
                  <Select.Option value={2}>Adesivo veicular</Select.Option>
                  <Select.Option value={3}>Placa</Select.Option>
                  <Select.Option value={4}>Perfurite</Select.Option>
                  <Select.Option value={5}>Todos</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ paddingRight: 10 }}>
              <Form.Item name="personCategoryId"
                label="Categoria">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderPersonCategoryOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col style={{ paddingRight: 10 }}>
              <Form.Item name="occupationId"
                label={"Profissão"}>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderOccupationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ paddingRight: 10 }}>
              <Form.Item name="religionId"
                label={"Religião"}>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderReligionOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col><h4>Informações de endereço</h4></Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Col span={12} style={{ paddingRight: 10 }}>
              <Form.Item name="neighborhood" label="Bairro">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {this.renderNeighborhoodOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <hr />

        <h4 style={{ textAlign: "center" }}>{
          this.state.data.length <= 0
            ? <Button type="default" onClick={this.handleSubmit} loading={this.state.loading}>Gerar relatório</Button>
            : <Button type='primary' onClick={this.download}>Download</Button>
        }</h4>
      </Card>
    );
  }
}
