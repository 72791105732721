import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthRoutes, NonAuthRoutes } from "./Routes";
import ProtectedRoutes from "./ProtectedRoutes";
import Login from "./Login/Login";
import PasswordReset from "./Login/PasswordReset";
import SignUp from "./SignUp/SignUp";
import { Layout } from "antd";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";

import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";

import Dashboard from "./Dashboard/Dashboard";

import PersonList from "./Person/List";
import PersonNew from "./Person/New";
import PersonEdit from "./Person/Edit";
import PersonDetail from "./Person/Detail";

import IssueList from './Issue/List';
import IssueNew from "./Issue/New";
import IssueEdit from "./Issue/Edit";
import IssueDetail from "./Issue/Detail";

import StatsPerson from "./Stats/Person";
import StatsIssue from "./Stats/Issue";

import CompanyList from "./Company/List";
import CompanyNew from "./Company/New";
import CompanyEdit from "./Company/Edit";

import LocalList from './Local/List';
import LocalNew from "./Local/New";
import LocalEdit from "./Local/Edit";
import LocalDetail from "./Local/Detail";

import UserList from './User/List';
import UserNew from "./User/New";
import UserEdit from "./User/Edit";
import UserDetail from "./User/Detail";

import MeetingList from './Meeting/List';
import MeetingNew from './Meeting/New';
import MeetingEdit from './Meeting/Edit';
import MeetingDetail from './Meeting/Detail';

import ProfileList from './User/Profile/List';
import ProfileNew from "./User/Profile/New";
import ProfileEdit from "./User/Profile/Edit";
import Unauthorized from "./Unauthorized";

import MapPerson from "./Map/MapPerson";
import Reports from "./Reports/Reports";
import Me from "./Account/Me";
import Payment from "./Account/Payment";
import Calendar from "./Calendar/List";
import Plan from "./Account/Plan";

const config = {
  locale: ptBR
};

const url = window.location.origin;
if (!url.includes('localhost') && !url.includes('https'))
  document.location.href = `https:${url.split(':')[1]}`

const App = () => {
  return (
    <ConfigProvider {...config}>
      <BrowserRouter>
        <Switch>
          <Route component={Login} path={NonAuthRoutes.login} exact />
          <Route component={SignUp} path={NonAuthRoutes.signUp} exact />
          <Route component={Unauthorized} path={"/unauthorized"} exact />
          <Route component={PasswordReset} path={AuthRoutes.userPasswordReset} exact />

          <ProtectedRoutes>
            <Layout>
              <Sidebar />
              <Layout className="site-layout">
                <Header />
                <Layout.Content style={{ margin: '10px 10px 0' }}>
                  <div style={{ padding: '20px 0px 20px 20px', minHeight: 360 }}>
                    <Switch>
                      <Route component={Dashboard} path={AuthRoutes.dashboard} exact />

                      <Route component={PersonList} path={AuthRoutes.person} exact />
                      <Route component={PersonNew} path={AuthRoutes.personNew} exact />
                      <Route component={PersonEdit} path={AuthRoutes.personEdit} exact />
                      <Route component={PersonDetail} path={AuthRoutes.personDetail} exact />

                      <Route component={IssueList} path={AuthRoutes.issue} exact />
                      <Route component={IssueNew} path={AuthRoutes.issueNew} exact />
                      <Route component={IssueEdit} path={AuthRoutes.issueEdit} exact />
                      <Route component={IssueDetail} path={AuthRoutes.issueDetail} exact />

                      <Route component={StatsPerson} path={AuthRoutes.statsPerson} exact/>
                      <Route component={StatsIssue} path={AuthRoutes.statsIssue} exact/>

                      <Route component={CompanyList} path={AuthRoutes.company} exact />
                      <Route component={CompanyNew} path={AuthRoutes.companyNew} exact />
                      <Route component={CompanyEdit} path={AuthRoutes.companyEdit} exact />

                      <Route component={LocalList} path={AuthRoutes.local} exact />
                      <Route component={LocalNew} path={AuthRoutes.localNew} exact />
                      <Route component={LocalEdit} path={AuthRoutes.localEdit} exact />
                      <Route component={LocalDetail} path={AuthRoutes.localDetail} exact />

                      <Route component={UserList} path={AuthRoutes.user} exact />
                      <Route component={UserNew} path={AuthRoutes.userNew} exact />
                      <Route component={UserEdit} path={AuthRoutes.userEdit} exact />
                      <Route component={UserDetail} path={AuthRoutes.userDetail} exact />

                      <Route component={ProfileList} path={AuthRoutes.profile} exact />
                      <Route component={ProfileNew} path={AuthRoutes.profileNew} exact />
                      <Route component={ProfileEdit} path={AuthRoutes.profileEdit} exact />
                      
                      <Route component={MeetingList} path={AuthRoutes.meeting} exact />
                      <Route component={MeetingNew} path={AuthRoutes.meetingNew} exact />
                      <Route component={MeetingEdit} path={AuthRoutes.meetingEdit} exact />
                      <Route component={MeetingDetail} path={AuthRoutes.meetingDetail} exact />

                      {/* <Route component={IndicatorList} path={AuthRoutes.indicator} exact />
                      <Route component={IndicatorNew} path={AuthRoutes.indicatorNew} exact />
                      <Route component={IndicatorEdit} path={AuthRoutes.indicatorEdit} exact />
                      <Route component={IndicatorDetail} path={AuthRoutes.indicatorDetail} exact /> */}

                      <Route component={Calendar} path={AuthRoutes.calendar} exact />

                      <Route component={MapPerson} path={AuthRoutes.map} exact />
                      <Route component={Reports} path={AuthRoutes.reports} exact />
                      
                      <Route component={Me} path={AuthRoutes.account} exact />
                      <Route component={Payment} path={AuthRoutes.payment} exact />
                      <Route component={Plan} path={AuthRoutes.plan} exact />

                      <Redirect to={AuthRoutes.dashboard} />
                    </Switch>
                  </div>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>Gibr ©2020 Created by Dev</Layout.Footer>
              </Layout>
            </Layout>
          </ProtectedRoutes>

        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
