import { Card, Col, Row } from 'antd';
import React from 'react'
import API from '../../lib/API';
import Column from '../../lib/charts/Column';
import Pie from '../../lib/charts/Pie';
import Breadcrumb from '../../lib/components/Breadcrumb';

export default class Person extends React.Component<any, any> {

    state: any = {
        chartPerNeighborhood: [],
        chartPerOccupation: [],
        chartPerReligion: [],
        chartPerCategory: [],
        chartPerSex: [],
        chartPerLocalToVote: []
    }

    fetchPersonPerLocalToVote = async () => this.setState({ chartPerLocalToVote: await API.get(`/stats/personPerLocalToVote`) });
    fetchPersonPerOccupation = async () => this.setState({ chartPerOccupation: await API.get(`/stats/personPerOccupation`) });
    fetchPersonPerNeighborhood = async () => this.setState({ chartPerNeighborhood: await API.get(`/stats/personPerNeighborhood`) });
    fetchPersonPerReligion = async () => this.setState({ chartPerReligion: await API.get(`/stats/personPerReligion`) });
    fetchPersonPerCategory = async () => this.setState({ chartPerCategory: await API.get(`/stats/personPerCategory`) });
    fetchPersonPerSex = async () => this.setState({ chartPerSex: await API.get(`/stats/personPerSex`) });

    componentDidMount = async () => {
        this.fetchPersonPerNeighborhood();
        this.fetchPersonPerLocalToVote();
        this.fetchPersonPerCategory();
        this.fetchPersonPerSex();
        this.fetchPersonPerOccupation();
        this.fetchPersonPerReligion();
    };

    render() {
        const { chartPerOccupation, chartPerCategory, chartPerSex, chartPerReligion, chartPerNeighborhood, chartPerLocalToVote } = this.state;

        return <div>
            <Breadcrumb crumbs={[{ name: "Estatísticas [Pessoas]" }]} />
            <label>Base completa</label>
            <Row>
                <Col>
                    <Card className="chartOne" title="Bairro">
                        <Column series={chartPerNeighborhood} horizontal={true}/> 
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="chartOne" title="Local de votação">
                        <Column series={chartPerLocalToVote} horizontal={true}/> 
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Categoria">
                        <Pie series={chartPerCategory} />
                    </Card>
                </Col>

                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Sexo">
                        <Pie series={chartPerSex} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Profissão">
                        <Pie series={chartPerOccupation} />
                    </Card>
                </Col>

                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Religião">
                        <Pie series={chartPerReligion} />
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}
