import React, { Component } from 'react';
import Joyride, { CallBackProps, STATUS, StoreHelpers } from 'react-joyride';
import Authentication from '../../lib/Authentication';

type State = {
    steps: any,
    run: boolean
};

class OnboardingDashboard extends Component<any, State> {

    private helpers?: StoreHelpers;

    state: any = {
        run: Authentication.isFirstAccess(),
        steps: [
            {
                title: 'Seja bem-vindo!',
                content: 'Para melhorar sua experiência, preparamos para você um tour de como utilizar as funcionalidades do GiBR. Você deseja iniciar agora?',
                placement: 'center',
                target: 'body',
            },
            {
                title: 'Menu de navegação',
                content: 'O menu consta todas as ferramentas disponíveis para uso.',
                placement: 'right',
                target: '.ant-menu-root'
            },
            {
                title: 'Menu de navegação',
                content: 'Seu tamanho poderá ser reduzido clicando na seta no canto inferior esquerdo.',
                target: '.ant-layout-sider-trigger'
            },
            {
                title: 'Dashboard',
                content: 'Tenha uma visão geral de como está a performance de seu gabinete.',
                placement: 'right',
                target: '.ant-menu-item-selected'
            },
            {
                title: 'Pessoas',
                content: 'Neste campo, é possível identificar quantas  pessoas ativos você possui na sua base de dados.',
                target: '.statisticPerson'
            },
            {
                title: 'Demandas',
                content: 'Ao lado, tem-se quantas demandas estão pendentes de resposta.',
                target: '.statisticIssue'
            },
            {
                title: 'Gráfico de cadastro de pessoas',
                content: <span>Neste gráfico é possível visualizar de forma fácil e prática o número de pessoas que foram cadastrados por dia, mês e ano. Para exportar essa informação, basta clicar no botão <img src='/assets/img/hamburguer.png' alt='hamburguer' /> localizado no canto superior direito.</span>,
                target: '.chartOne'
            }
        ]
    }

    private getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
    };

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED];

        if (finishedStatuses.includes(status)) {
            this.setState({ run: false });
            window.location.href = '/person';
        } else if (status === STATUS.SKIPPED)
            Authentication.setIsFirstAccess();
    };

    render() {

        const { run, steps } = this.state;
        return (
            <Joyride
                callback={this.handleJoyrideCallback}
                continuous={true}
                getHelpers={this.getHelpers}
                run={run}
                scrollToFirstStep={true}
                showProgress={false}
                showSkipButton={true}
                disableCloseOnEsc
                disableScrolling
                steps={steps}
                styles={{
                    options: {
                        primaryColor: '#0c0c0c',
                        zIndex: 10000,
                    },
                    buttonNext: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    buttonBack: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    buttonSkip: {
                        fontSize: '0.83em',
                        fontWeight: 500
                    },
                    tooltipContent: {
                        textAlign: 'left'
                    }
                }}
                locale={{
                    next: 'Próximo',
                    back: 'Voltar',
                    skip: 'Continuar depois',
                    last: 'Próximo',
                }}
            />
        );
    }
}

export default OnboardingDashboard;
