import React, { Component } from "react";
import Modal from "../../../lib/components/Modal";
import { Item } from "./interfaces";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import Api from "../../../lib/API";
import Authentication from "../../../lib/Authentication";

type State = {
  saveLoading: boolean;
};

type Props = {
  title: string;
  modelItem?: Item;
  parentId: any;
  item: string;
  visible: boolean;
  onClose: () => void;
  afterSaveItem: (res: any) => void;
};

class NewItem extends Component<Props, State> {
  state: State = {
    saveLoading: false
  };

  private formRef = React.createRef<FormInstance>();

  saveItem = async (modelItem: any) => {
    this.setState({ saveLoading: true });
    this.props.afterSaveItem(
      (this.props.parentId)
        ? await Api.post(`/${this.props.item}`, { ...modelItem, issueCategoryId: this.props.parentId, clientId: Authentication.getUserClient() })
        : await Api.post(`/${this.props.item}`, { ...modelItem, clientId: Authentication.getUserClient() })
    );
    this.setState({ saveLoading: false })
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveItem(values);
    this.formRef.current?.resetFields();
  };

  render() {
    const { visible, title } = this.props;
    const { saveLoading } = this.state;

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    };

    return (
      <Modal
        okButtonProps={{ loading: saveLoading }}
        onOk={this.handleSubmit}
        okText={"Salvar"}
        cancelText={"Cancelar"}
        onCancel={this.props.onClose}
        visible={visible}
        title={title}
      >
        <Form initialValues={this.props.modelItem} ref={this.formRef} layout="horizontal">
          <Form.Item name="name" label="Descrição" {...formItemLayout}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NewItem;
