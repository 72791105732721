import React, { Component } from "react";
import Table, { ColumnProps } from "../../../lib/components/Table";
import { Profile } from "./interfaces";
import Api from "../../../lib/API";
import { Divider, Popconfirm, Button, Input } from "antd";
import Breadcrumb from "../../../lib/components/Breadcrumb";
import { EditOutlined, DeleteOutlined, TeamOutlined, UndoOutlined, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import OnboardingProfile from "../../Onboarding/Profile";


type State = {
  records: Profile[];
  profile?: Profile;
  loading: boolean;
  searchText: string;
  searchedColumn: string;
};

export default class ListProfile extends Component<any, State> {
  state: State = {
    loading: false,
    records: [],
    searchText: '',
    searchedColumn: '',
  };

  searchInput: any;


  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
        <Divider type="vertical" />
        <Button onClick={() => this.handleReset(clearFilters)}
          size="small" style={{ width: 90 }}
          icon={<CloseCircleOutlined />} />

      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: any) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  columns: ColumnProps<Profile>[] = [
    {
      dataIndex: "name",
      title: "Nome",
      ...this.getColumnSearchProps('name'),
    },
    {
      dataIndex: "description",
      title: "Descrição",

    },
    {
      title: "",
      key: "action",
      render: (_, profile) => (
        profile.deleted_at && !profile.isDefault
          ? <span>
            <Popconfirm title={"Deseja reativar este perfil?"}
              placement="top"
              icon={<TeamOutlined />}
              onConfirm={() => this.onRestore(profile)}
              cancelText={"Cancelar"}
              okText={"Reativar"}>
              <UndoOutlined />
            </Popconfirm>
          </span>
          : !profile.isDefault
            ? <span>
              <EditOutlined onClick={() => { this.props.history.push(`/profile/edit/${profile.id}`) }} />
              <Divider type="vertical" />
              <Popconfirm title={"Deseja remover este perfil?"}
                placement="top"
                icon={<TeamOutlined />}
                onConfirm={() => this.onDelete(profile)}
                cancelText={"Cancelar"}
                okText={"Deletar"}
                okType={"danger"}>
                <DeleteOutlined />
              </Popconfirm>
            </span>
            : null
      )
    }
  ];
  componentDidMount(): void {
    this.fetchProfile().catch();
  }

  async fetchProfile() {
    this.setState({ loading: true });
    const records = await Api.get(`/profile`);
    this.setState({ records, loading: false });
  }

  onDelete = async (profile: Profile) => {
    this.setState({ loading: true });

    await Api.delete(`/profile/${profile.id}`);

    this.fetchProfile();
  };

  onRestore = async (profile: Profile) => {
    this.setState({ loading: true });

    await Api.put(`/profile/${profile.id}/restore`);

    this.fetchProfile();
  };

  render() {
    const { loading, records } = this.state;

    return (
      <div>
        <OnboardingProfile />
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Usuários" }, { name: "Perfis de acesso" }]} />
          <div>
            <Button type="primary" style={{ marginBottom: 16 }}
              onClick={() => { this.props.history.push(`/profile/new`) }}>
              Novo perfil
          </Button>
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <Table
            loading={loading}
            columns={this.columns}
            dataSource={records}
            style={{ paddingTop: 10 }} />
        </div>
      </div>
    );
  }
}
