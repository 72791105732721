const NonAuthRoutes = {
    login: "/login",
    signUp: "/signup",
    default: "/"
};

const AuthRoutes = {
    dashboard: "/dashboard",
    person: "/person",
    personNew: "/person/new",
    personEdit: "/person/edit/:id",
    personDetail: "/person/view/:id",
    issue: "/issue",
    issueNew: "/issue/new",
    issueEdit: "/issue/edit/:id",
    issueDetail: "/issue/view/:id",
    statsPerson: "/stats_person",
    statsIssue: "/stats_issue",
    company: "/company",
    companyNew: "/company/new",
    companyEdit: "/company/edit/:id",
    local: "/local",
    localNew: "/local/new",
    localEdit: "/local/edit/:id",
    localDetail: "/local/view/:id",
    reports: "/reports",
    user: "/user",
    userNew: "/user/new",
    userEdit: "/user/edit/:id",
    userDetail: "/user/view/:id",
    userPasswordReset: "/password-reset",
    profile: "/profile",
    profileNew: "/profile/new",
    profileEdit: "/profile/edit/:id",
    map: "/map",
    meeting: "/meeting",
    meetingNew: "/meeting/new",
    meetingEdit: "/meeting/edit/:id",
    meetingDetail: "/meeting/view/:id",
    indicator: "/indicator",
    indicatorNew: "/indicator/new",
    indicatorEdit: "/indicator/edit/:id",
    indicatorDetail: "/indicator/view/:id",
    calendar: "/calendar",
    account: "/account",
    payment: "/payment",
    plan: "/plan"
};

export {
    NonAuthRoutes, AuthRoutes
}