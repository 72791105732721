import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Row, Spin, Switch } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import React, { Component } from "react";
import Api from "../../lib/API";
import Authentication from "../../lib/Authentication";
import { User } from "./interfaces";

type State = {
  user: User | any;
  loading: boolean;
  visible: boolean;
}

export default class UserDetail extends Component<any, State>{
  usersId: string = this.props.match.params.id;
  userId: string = Authentication.getUserId();

  state: State = {
    user: null,
    loading: false,
    visible: false
  };

  componentDidMount(): void{
    this.fetchUser().catch();
  }

  async fetchUser() {
    this.setState({ loading: true});
    const user = await Api.get(`/user/${this.usersId}`);
    this.setState({ user, loading: false })
  }

  render() {
    const { loading, user } = this.state;


    if (!user)
      return <div style={{ textAlign: "center" }}><Spin spinning={loading} /></div>;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Usuários" }, { name: user.name }]} />
        <Row>
          <Col>
            <Card loading={loading}>
              <Descriptions>
                <Descriptions.Item>
                  Nome: 
                  <strong>
                    {user.name}
                  </strong>
                </Descriptions.Item>
                <Descriptions.Item>
                  E-mail:
                  <strong>
                    {user.email}
                  </strong>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col>
            <Card loading={loading}>
              <Row>
                <h3>
                  Gerenciar Notificações
                </h3>
              </Row>
              <Descriptions>
                <Descriptions.Item>
                  <Row>
                    <h4>Notificação sobre período de inatividade:</h4>
                  </Row>
                  <Row>
                    <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item>
                  <Row>
                    <h4>Notificação sobre período de demanda:</h4>
                  </Row>
                  <Row>
                    <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item>
                  <Row>
                    <h4>Notificação sobre vencimento de fatura:</h4>
                  </Row>
                  <Row>
                    <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    />
                  </Row>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
    )  
  }
} 
