import React, { Component } from "react";
import Table, { ColumnProps } from "../../lib/components/Table";
import { User } from "./interfaces";
import Api from "../../lib/API";
import { Divider, Popconfirm, Button, Input } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { EditOutlined, DeleteOutlined, EyeOutlined, TeamOutlined, UndoOutlined, SearchOutlined, CloseCircleOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import OnboardingUser from "../Onboarding/User";


type State = {
  stateFilter: string;
  records: User[];
  user?: User;
  showNewRecordModal: boolean;
  loading: boolean;
  searchText: string;
  searchedColumn: string;
};

export default class ListUser extends Component<any, State> {
  state: State = {
    loading: false,
    records: [],
    showNewRecordModal: false,
    stateFilter: "withoutTrashed",
    searchText: '',
    searchedColumn: '',
  };

  searchInput: any;


  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        />
        <Divider type="vertical" />
        <Button onClick={() => this.handleReset(clearFilters)}
          size="small" style={{ width: 90 }}
          icon={<CloseCircleOutlined />} />

      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: any) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  columns: ColumnProps<User>[] = [
    {
      dataIndex: "name",
      title: "Nome",
      ...this.getColumnSearchProps('name'),
    },
    {
      dataIndex: "email",
      title: "Email",

    },
    {
      title: "",
      key: "action",
      render: (_, user) => (
        user.deleted_at
          ? <span>
            <Popconfirm title={"Deseja reativar este usuário?"}
              placement="top"
              icon={<TeamOutlined />}
              onConfirm={() => this.onRestore(user)}
              cancelText={"Cancelar"}
              okText={"Reativar"}>
              <UndoOutlined />
            </Popconfirm>
          </span>
          : <span>
            <EyeOutlined onClick={() => { this.props.history.push(`/user/view/${user.id}`) }} />
            <Divider type="vertical" />
            <EditOutlined onClick={() => { this.props.history.push(`/user/edit/${user.id}`) }} />
            <Divider type="vertical" />
            <Popconfirm title={"Deseja enviar uma nova senha para este usuário?"}
              placement="top"
              icon={<UserOutlined />}
              onConfirm={() => this.onResetPassword(user)}
              cancelText={"Cancelar"}
              okText={"Enviar nova senha"}
              okType="dashed">
              <SendOutlined />
            </Popconfirm>
            <Divider type="vertical" />
            <Popconfirm title={"Deseja remover este usuário?"}
              placement="top"
              icon={<UserOutlined />}
              onConfirm={() => this.onDelete(user)}
              cancelText={"Cancelar"}
              okText={"Deletar"}
              okType={"danger"}>
              <DeleteOutlined />
            </Popconfirm>
          </span>
      )
    }
  ];
  componentDidMount(): void {
    this.fetchUser().catch();
  }

  async fetchUser() {
    this.setState({ loading: true });
    const records = await Api.get(`/user`);
    this.setState({ records, loading: false });
  }

  onDelete = async (user: User) => {
    this.setState({ loading: true });

    await Api.delete(`/user/${user.id}`);

    this.fetchUser();
  };

  onRestore = async (user: User) => {
    this.setState({ loading: true });

    await Api.put(`/user/${user.id}/restore`);

    this.setState({ stateFilter: "withoutTrashed" }, () => this.fetchUser());
  };

  onResetPassword = async (user: User) => {
    this.setState({ loading: true });

    await Api.put(`/user/resetPassword/${user.id}`);

    this.fetchUser();
  };

  render() {
    const { loading, records } = this.state;

    return (
      <div>
        <OnboardingUser history={this.props.history} />
        <div className="flex space-between">
          <Breadcrumb crumbs={[{ name: "Usuários" }]} />
          <div>
            <Button type="default" style={{ marginBottom: 16 }}
              onClick={() => { this.props.history.push(`/profile`) }}>
              Perfis de acesso
          </Button>
            <Divider type="vertical" />
            <Button type="primary" style={{ marginBottom: 16 }}
              onClick={() => { this.props.history.push(`/user/new`) }}>
              Novo usuário
          </Button>
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <Table
            loading={loading}
            columns={this.columns}
            dataSource={records}
            style={{ paddingTop: 10 }} />
        </div>
      </div>
    );
  }
}
