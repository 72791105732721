import React from "react";
import { Layout, Menu } from "antd";
import { DashboardOutlined, UserOutlined, FormOutlined, TeamOutlined, FileSearchOutlined, ShopOutlined, RadarChartOutlined, DollarCircleOutlined, HomeOutlined, CalendarOutlined, BuildOutlined, AreaChartOutlined, PieChartOutlined } from "@ant-design/icons";
import { translate as t } from "../../translator/translate";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AuthRoutes } from "../Routes";

const { Sider } = Layout;

const SidebarItems = [
  { name: "Dashboard", icon: DashboardOutlined, to: AuthRoutes.dashboard },
  { name: "Pessoas", icon: TeamOutlined, to: AuthRoutes.person },
  { name: "Demandas", icon: FormOutlined, to: AuthRoutes.issue },
  { name: "Pessoas [Estatísticas]", icon: AreaChartOutlined, to: AuthRoutes.statsPerson },
  { name: "Demandas [Estatísticas]", icon: PieChartOutlined, to: AuthRoutes.statsIssue },
  { name: "Empresas & Parceiros", icon: BuildOutlined, to: AuthRoutes.company },
  // { name: "Pesquisas", icon: PieChartOutlined, to: AuthRoutes.indicator },
  { name: "Locais de votação", icon: ShopOutlined, to: AuthRoutes.local },
  { name: "Mapa de pessoas", icon: RadarChartOutlined, to: AuthRoutes.map },
  { name: "Agenda", icon: CalendarOutlined, to: AuthRoutes.calendar },
  { name: "Reuniões & Eventos", icon: HomeOutlined, to: AuthRoutes.meeting },
  { name: "Relatórios", icon: FileSearchOutlined, to: AuthRoutes.reports },
  { name: "Usuários", icon: UserOutlined, to: AuthRoutes.user },
  { name: "Pagamento", icon: DollarCircleOutlined, to: AuthRoutes.payment }
];

const SidebarLink = (props: { name: string; to: string }) => {
  return (
    <>
      <span>{props.name}</span>
      <Link to={props.to} />
    </>
  );
};

class Sidebar extends React.Component<RouteComponentProps> {
  state = {
    collapsed: false,
    basePath: ''
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  UNSAFE_componentWillMount = () => this.setState({ basePath: this.props.location.pathname.split("/").slice(0, 2).join("/") })

  render() {
    const { basePath } = this.state;

    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        style={{ minHeight: '100vh', backgroundColor: '#0c0c0c' }}
      >
        <div className="header-logo" style={{ padding: 0, marginTop: -10 }}>
          <img src="/assets/img/logo_white.png" alt="logo" width="125" />
        </div>
        <Menu
          theme="dark"
          style={{ backgroundColor: '#0c0c0c' }}
          mode="inline"
          defaultSelectedKeys={[basePath]}
        >
          {SidebarItems.map(item => {
            const Icon = item.icon;
            return (
              <Menu.Item key={item.to}>
                <Icon />
                <SidebarLink to={item.to} name={t(item.name)} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);
