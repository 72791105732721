import React, { Component } from "react";
import { Meeting } from "./interfaces";
import { Person } from '../Person/interfaces';
import { MeetingParticipants } from './Form/MeetingParticipantsInterfaces';
import Api from "../../lib/API";
import { Button, Spin, Row, Col, Card, Table, Descriptions, message, Popconfirm } from "antd";
import Breadcrumb from "../../lib/components/Breadcrumb";
import { DeleteOutlined, EditOutlined, TeamOutlined } from "@ant-design/icons";
import Authentication from "../../lib/Authentication";
import { ColumnProps } from "../../lib/components/Table";
import NewItem from "./Form/NewItem";

type State = {
  meeting: Meeting | any;
  loading: boolean;
  people: Array<Person> | Array<any>;
  meetingParticipants: Array<MeetingParticipants> | Array<any>;
  visible: boolean;
  saveLoading: boolean;
  newMeetingParticipants: boolean;
};

export default class DetailMeeting extends Component<any, State> {
  meetingId: string = this.props.match.params.id;
  userId: string = Authentication.getUserId();
  

  state: State = {
    meeting: null,
    loading: false,
    people: [],
    meetingParticipants: [],
    visible: false,
    saveLoading: false,
    newMeetingParticipants: false
  };

  columns: ColumnProps<MeetingParticipants>[] = [
    {
      dataIndex: "person",
      title: "Nome",
      render: (_: any, meetingParticipants: any) => meetingParticipants.person ? meetingParticipants.person.name : ''
    },
    {
      title: "Ações",
      key: "action",
      render: (_, meetingParticipants) => (
        <span>
          <Popconfirm title={"Deseja remover este convidado?"}
                placement="top"
                icon={<TeamOutlined />}
                onConfirm={() => this.onDelete(meetingParticipants)}
                cancelText={"Cancelar"}
                okText={"Deletar"}
                okType={"danger"}>
                <DeleteOutlined />
          </Popconfirm>
        </span>
      )
    }
  ];

  componentDidMount(): void {
    this.fetchMeeting().catch();
    this.fetchMeetingParticipants().catch();
  }

  async fetchMeeting() {
    this.setState({ loading: true });
    const meeting = await Api.get(`/meeting/${this.meetingId}`);
    this.setState({ meeting, loading: false });
  }

  async fetchMeetingParticipants() {
    this.setState({ loading: true });
    const meetingParticipants = await Api.get(`/meetingParticipants/${this.meetingId}`);
    this.setState({ meetingParticipants, loading: false });
  }

  afterSaveItem = async (res: any) => {
    if (res) {
      message.success("Salvo com sucesso");
      this.fetchMeetingParticipants();
    }

    this.setState({ newMeetingParticipants: false });
  };

  onDelete = async (meetingParticipants: MeetingParticipants) => {
    this.setState({ loading: true });

    await Api.delete(`/meetingParticipants/${meetingParticipants.id}`);

    this.fetchMeetingParticipants();
  };

  render() {
    const { loading, meeting, meetingParticipants, newMeetingParticipants } = this.state;

    if (!meeting)
      return <div style={{ textAlign: "center" }}><Spin spinning={loading} /></div>;

    const address = meeting.haveSameAddress ? meeting.person : meeting;

    return (
      <div>
        <Breadcrumb crumbs={[{ name: "Reuniões & Eventos" }, { name: meeting.title }]} />
        <Row>
          <Col md={12}>
            <Row style={{ paddingTop: 0 }}>
              <Col>
                <Card loading={loading} title="Informações da Reunião ou evento" actions={[
                  <div onClick={() => { this.props.history.push(`/meeting/edit/${meeting.id}`) }}>
                    <EditOutlined /> Editar Reunião ou Evento
                      </div>
                ]}>
                  <Descriptions>
                    <p>Anfitrião: <strong>{meeting.person.name}</strong></p>
                  </Descriptions>
                  <Descriptions>
                    <p>Descrição <strong>{meeting.description}</strong></p>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="Data e Hora">{meeting.when}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </Row>
            <Row style={{ paddingTop: 5 }}>
              <Col>
                <Card loading={loading} title="Endereço da Reunião ou Evento">
                  <Descriptions>
                    <Descriptions.Item label="CEP">{address.zipcode}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="Endereço">{address.address}</Descriptions.Item>
                    <Descriptions.Item label="Número">{address.number}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions>
                    <Descriptions.Item label="Bairro">{address.neighborhood}</Descriptions.Item>
                    <Descriptions.Item label="Cidade">{address.city.name}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Card loading={loading} title={<div>
              Convidados
                <Button className="right" type="primary"
                onClick={() => this.setState({ newMeetingParticipants: true })}>
                Adicionar convidados
                </Button>
            </div>}>
              <Table size="small" columns={this.columns} dataSource={meetingParticipants} />
            </Card>
          </Col>
        </Row>
        <NewItem
          meetingId={meeting.id}
          visible={newMeetingParticipants}
          afterSaveItem={this.afterSaveItem}
          onClose={() => this.setState({ newMeetingParticipants: false })}
        />
      </div>
    );
  }
}