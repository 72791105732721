import { Card, Col, Row } from 'antd';
import React from 'react'
import API from '../../lib/API';
import Column from '../../lib/charts/Column';
import Line from '../../lib/charts/Line';
import Pie from '../../lib/charts/Pie';
import Breadcrumb from '../../lib/components/Breadcrumb';

export default class Issue extends React.Component<any, any> {

    state: any = {
        chartPerStatus: [],
        chartPerCategory: [],
        chartPerSubcategory: [],
        chartPerResponsible: [],
        chartPerNeighborhood: [],
        chartPerYearAndMonth: []
    }

    fetchIssuePerYearAndMonth = async () => this.setState({ chartPerYearAndMonth: await API.get(`/stats/issuePerYearAndMonth`) });
    fetchIssuePerNeighborhood = async () => this.setState({ chartPerNeighborhood: await API.get(`/stats/issuePerNeighborhood`) });
    fetchIssuePerStatus = async () => this.setState({ chartPerStatus: await API.get(`/stats/issuePerStatus`) });
    fetchIssuePerSubcategory = async () => this.setState({ chartPerSubcategory: await API.get(`/stats/issuePerSubcategory`) });
    fetchIssuePerCategory = async () => this.setState({ chartPerCategory: await API.get(`/stats/issuePerCategory`) });
    fetchIssuePerResponsible = async () => this.setState({ chartPerResponsible: await API.get(`/stats/issuePerResponsible`) });

    mountDataForChartLine = (data: any) => {
        let dataMounted: any = [];

        data.forEach((e: any) => {
            if (!dataMounted.find((o: any) => o.name === e.year))
                dataMounted.push({
                    name: e.year,
                    data: Array(12).fill(0)
                })

            let p = dataMounted.find((o: any) => o.name === e.year)
            p.data[e.month - 1] = e.total;
        });

        return dataMounted;
    }

    componentDidMount = async () => {
        this.fetchIssuePerYearAndMonth();
        this.fetchIssuePerStatus();
        this.fetchIssuePerNeighborhood();
        this.fetchIssuePerCategory();
        this.fetchIssuePerResponsible();
        this.fetchIssuePerSubcategory();
    };

    render() {
        const { chartPerTime,
            chartPerCategory,
            chartPerResponsible,
            chartPerSubcategory,
            chartPerStatus,
            chartPerNeighborhood,
            chartPerYearAndMonth } = this.state;

        return <div>
            <Breadcrumb crumbs={[{ name: "Estatísticas [Demandas]" }]} />
            <label>Base completa</label>
            <Row>
                <Col>
                    <Card className="chartOne" title="Por ano/mês">
                        <Line series={this.mountDataForChartLine(chartPerYearAndMonth)} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={24} md={24} lg={24}>
                    <Card className="chartOne" title="Status">
                        <Pie series={chartPerStatus} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Categoria">
                        <Pie series={chartPerCategory} />
                    </Card>
                </Col>
                <Col sm={24} md={12} lg={12}>
                    <Card className="chartOne" title="Subcategoria">
                        <Pie series={chartPerSubcategory} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="chartOne" title="Bairro">
                        <Column series={chartPerNeighborhood} horizontal={true} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card className="chartOne" title="Responsável">
                        <Column series={chartPerResponsible} horizontal={true} />
                    </Card>
                </Col>
            </Row>
        </div>;
    }
}
