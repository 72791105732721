import React from 'react'
import { translate as t } from "../../translator/translate";
import Breadcrumb from '../../lib/components/Breadcrumb';
import { Row, Col, Card, Form, Input, Select, message, Button } from 'antd';
import Api from '../../lib/API';
import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';
import { FormInstance } from "antd/lib/form";
import cep from 'cep-promise';

type State = {
	loading: boolean,
	saveLoading: boolean,
	client: Array<any>,
	cities: any
};


export default class Me extends React.Component<any, State> {

	state: any = {
		saveLoading: false,
		loading: true,
		client: [],
		cities: []
	}

	private formRef = React.createRef<FormInstance>();

	async fetchTables() {
		const cities = await Api.get(`/city`);
		const client = await Api.get(`/client/my`);

		this.setState({ cities, client, loading: false });
	}

	async componentDidMount() {
		this.setState({ loading: true });
		this.fetchTables();
	}

	get renderCitiesOptions() {
		return this.state.cities.map((c: any, key: any) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
	}

	searchZipcode = async (zipcode: any) => {
		let { client, cities } = this.state;

		try {
			if (zipcode !== "") {
				const res = await cep(zipcode).then().catch();

				client.zipcode = zipcode;
				client.address = res.street;
				client.neighborhood = res.neighborhood;
				client.cityId = cities.find((o: any) => o.name === res.city).id;
				this.setState({ client });
				this.formRef.current?.setFieldsValue(client);
			}
		} catch (ex) {
			message.warning("Digite um CEP válido")
		}
	}


	handleSubmit = async () => {
		this.setState({ saveLoading: true });
		const client = await this.formRef.current?.validateFields();

		await Api.put(`/client/my`, client);

		this.setState({ saveLoading: false });
		message.success("Salvo com sucesso");
	};

	render() {
		const { saveLoading, loading, client } = this.state;
		return <div>

			<Breadcrumb crumbs={[{ name: t("Minha conta") }]} />
			<Row>
				<Col>
					<Row style={{ paddingTop: 0 }}>
						<Col>
							<Card loading={loading}>
								<Form initialValues={client}
									onFinish={this.handleSubmit}
									ref={this.formRef}
									layout="vertical">
									<Row style={{ paddingTop: 0 }}>
										<Col span={9} style={{ paddingRight: 10 }}>
											<Form.Item name="name" label="Nome" rules={[{ required: true, message: "Preencha o campo para salvar" }]}>
												<Input />
											</Form.Item>
										</Col>
										<Col span={10} style={{ paddingRight: 10 }}>
											<Form.Item name="email" label="E-mail" rules={[{ required: true }]}>
												<Input />
											</Form.Item>
										</Col>
										<Col span={5} style={{ paddingRight: 10 }}>
											<Form.Item name="cpf" label="CPF" rules={[{ required: true }]}>
												<MaskedInput mask="111.111.111-11" />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={3} style={{ paddingRight: 10 }}>
											<Form.Item name="zipcode" label="CEP">
												<MaskedInput mask="11111-111"
													onBlurCapture={(e) => this.searchZipcode(e.target.value)}
												/>
											</Form.Item>
										</Col>
										<Col span={8} style={{ paddingRight: 10 }}>
											<Form.Item name="address" label="Endereço">
												<Input />
											</Form.Item>
										</Col>
										<Col span={2} style={{ paddingRight: 10 }}>
											<Form.Item name="number" label="Número">
												<Input type="number" />
											</Form.Item>
										</Col>
										<Col span={3} style={{ paddingRight: 10 }}>
											<Form.Item name="complement" label="Complemento">
												<Input />
											</Form.Item>
										</Col>
										<Col span={4} style={{ paddingRight: 10 }}>
											<Form.Item name="neighborhood" label="Bairro">
												<Input />
											</Form.Item>
										</Col>
										<Col span={4} style={{ paddingRight: 10 }}>
											<Form.Item name="cityId" label="Cidade (UF)">
												<Select
													showSearch
													filterOption={(input, option: any) =>
														option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
													}>
													{this.renderCitiesOptions}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col span={24} style={{ textAlign: 'right' }}>
											<Button type="primary" htmlType="submit" loading={saveLoading}>
												Salvar
                                        </Button>
										</Col>
									</Row>
								</Form>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>;
	}
}
