import React from 'react'
import { Button, Select, Input, Form, Card, message } from "antd"
import Api from "../../lib/API";
import { RouteComponentProps, Link } from "react-router-dom"
import { translate as t } from "../../translator/translate";

import MaskedInput from 'antd-mask-input/build/main/lib/MaskedInput';

export default class SignUp extends React.Component<RouteComponentProps> {
    state = {
        saveLoading: false,
        cities: []
    };

    async fetchCities() {
        const cities = await Api.get(`/city`);
        this.setState({ cities });
    }

    get renderCitiesOptions() {
        return this.state.cities.map((c: any, key: number) => <Select.Option key={key} value={c.id}>{c.name} - {c.uf.code}</Select.Option>)
    }

    componentDidMount = async () => await this.fetchCities();

    render() {
        return (
            <div className="screen-container">
                <div className="image-background">
                    <img src="/assets/img/planalto.png" alt='back' style={{ width: "100%", height: 600 }} />
                </div>
                <Card className="form-container">
                    <Form name="signUp" layout="vertical" onFinish={this.handleSubmit} size="large">
                        <h1 style={{ textAlign: "center" }}>
                            <img src="/assets/img/logo.png" alt='logo' width="125" />
                        </h1>
                        <h4 style={{ textAlign: "center" }}>Criar minha conta</h4>

                        <Form.Item name="name" label={t("Nome")} rules={[{ required: true, message: "Preencha o seu nome" }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="email" label={t("E-mail")} rules={[{ required: true, type: "email", message: "Preencha um email válido" }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="phone" label="Telefone" rules={[{ required: true, message: "Preencha o seu telefone" }]}>
                            <MaskedInput mask="(11) 11111-1111" />
                        </Form.Item>

                        <Form.Item name="cityId" label="Cidade (UF)" rules={[{ required: true, message: "Selecione a sua cidade" }]}>
                            <Select
                                showSearch
                                filterOption={(input, option: any) =>
                                    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.renderCitiesOptions}
                            </Select>
                        </Form.Item>

                        <hr />

                        <div className="flex space-between align-center">
                            <Link to="/login/" className="fs-20 left">{t("Voltar")}</Link>
                            <Button type="primary" htmlType="submit" loading={this.state.saveLoading}>{t("CADASTRAR")}</Button>
                        </div>
                    </Form>
                </Card>
            </div>

        );
    }

    handleSubmit = async (values: any) => {
        this.setState({ saveLoading: true });

        const res = await Api.post("/createClientAndUser", values);

        this.setState({ saveLoading: false });

        if (res && res.error)
            message.error(res.error);
        else {
            message.success("Sua conta foi criado com sucesso! Estamos enviando suas credenciais...")
            this.props.history.push("/login");
        }
    }
}