import React, { Component } from "react";
import Modal from "../../../lib/components/Modal";
import { Form, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import Api from "../../../lib/API";

type State = {
  saveLoading: boolean;
  people: Array<any>;
};

type Props = {
  meetingId: number;
  visible: boolean;
  onClose: () => void;
  afterSaveItem: (res: any) => void;
};

class NewItem extends Component<Props, State> {
  state: State = {
    saveLoading: false,
    people: []
  };

  private formRef = React.createRef<FormInstance>();

  async fetchTables() {
    const people = await Api.get(`/person`);

    this.setState({ people });
  }

  saveItem = async (data: any) => {
    this.setState({ saveLoading: true });
    this.props.afterSaveItem(
      await Api.post(`/meetingParticipants`, { ...data, meetingId: this.props.meetingId })
    );
    this.setState({ saveLoading: false })
  };

  handleSubmit = async () => {
    const values = await this.formRef.current?.validateFields();
    await this.saveItem(values);
    this.formRef.current?.resetFields();
  };

  get renderPersonOptions() {
    return this.state.people.map((person, key) => <Select.Option key={key} value={person.id} disabled={person.deleted_at != null}>{person.name}</Select.Option>)
  }

  componentDidMount(): void {
    this.fetchTables();
  }

  render() {
    const { visible } = this.props;
    const { saveLoading } = this.state;


    return (
      <Modal
        okButtonProps={{ loading: saveLoading }}
        onOk={this.handleSubmit}
        okText={"Salvar"}
        cancelText={"Cancelar"}
        onCancel={this.props.onClose}
        visible={visible}
        title={"Novo convidado"}
      >
        <Form ref={this.formRef} layout="horizontal">
          <Form.Item name="personId" label="Convidado" rules={[{ required: true, message: "Selecione o convidado" }]}>
            <Select
              showSearch
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {this.renderPersonOptions}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default NewItem;
