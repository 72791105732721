import React from 'react'
import { Link } from "react-router-dom"
import { Button, Form, Input, message } from "antd"
import Api from "../../lib/API";
import { RouteComponentProps } from "react-router-dom"
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { translate as t } from "../../translator/translate";
import Authentication from "../../lib/Authentication";

export default class Login extends React.Component<RouteComponentProps> {
    state = {
        loading: false
    };

    render() {
        return (
            <div className="screen-container">
                <div className="image-background">
                    <img src="/assets/img/planalto.png" alt='back' style={{ width: "100%", height: 600 }} />
                </div>
                <div className="form-container">
                    <Form name="login" layout="vertical" onFinish={this.handleSubmit}>
                        <h1 style={{ textAlign: "center" }}>
                            <img src="/assets/img/logo.png" alt='logo' width="125" />
                        </h1>

                        <br />

                        <Form.Item name="email" rules={[{ required: true, type: "email" }]}>
                            <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Insira o seu e-mail")} />
                        </Form.Item>

                        <Form.Item name="password" rules={[{ required: true }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Insira a sua senha")} />
                        </Form.Item>

                        <hr />

                        <div className="flex align-center">
                            {/* <Link to="/login/" className="fs-20">{t("Esqueceu sua senha?")}</Link> */}

                        </div>
                        <div className="flex align-center space-between">
                            <Link to="/signup/" className="fs-20">{t("Criar minha conta")}</Link>
                            <Button loading={this.state.loading} type="primary" htmlType="submit">{t("LOGIN")}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });

        const res = await Api.post("/auth/login", values);

        this.setState({ loading: false });

        if (res === "isSuspended")
            return message.error("Sua conta foi suspensa.");


        if (!res)
            return message.warning('Verifique seu usuário e/ou senha')

        Authentication.login(res.jwt, res.user);

        if (res.user.isFirstAccess || res.user.resetPassword)
            this.props.history.push("/password-reset")
        else
            this.props.history.push("/dashboard")
    }
}
