import config from "../config";

interface Dictionary {
    [key: string]: Translation
}

interface Translation {
    [key: string]: string
} 

const dictionary : Dictionary = require("./dictionary.json");

export function getLanguage() {
    return localStorage.userLanguage || config.defaultLanguage;
}

export function setLanguage(language : string) {
    localStorage.userLanguage = language;
}

//TODO: string replacement using params 
export function translate(text: string) {
    const lowerCaseText : string = text.trim().toLowerCase();
    const dict : Translation = dictionary[lowerCaseText];

    return findLanguage(dict) || text;
}

function findLanguage(textDict: Translation) {
    const lang = getLanguage();

    if (!textDict || !textDict[config.defaultLanguage])
        return null;


    return textDict[lang] || (textDict[config.defaultLanguage]);
}

export default {
    translate,
    getLanguage,
    setLanguage
}